angular.module('dell-repository.error', ['ui.router', 'taiPlaceholder', 'ui.bootstrap']).config(function config($stateProvider) {
	$stateProvider.state('error', {
		url : '/error',
		views : {
			"main" : {
				controller : 'ErrorCtrl',
				templateUrl : 'verification/error/error.tpl.html'
			}
		},
		data : {
			pageTitle : 'DELL Retail Library'
		}
	});
}).controller('ErrorCtrl', function($scope, $state) {});
