angular.module('dell-repository.shared', []).
controller('ConfirmModalCtrl', function ConfirmModalCtrl($scope, $uibModalInstance) {
    $scope.proceedConfirmation = function() {
        $uibModalInstance.close();
    };

    $scope.cancelConfirmation = function() {
        $uibModalInstance.dismiss('cancel');
    };
}).service('SharedFunctionsService', function() {
    return {
        getExtension: function(filename) {
            var parts = filename.split(".");
            return parts[parts.length - 1];
        },
        getFileName: function(file) {
            return file.substring(0, file.lastIndexOf("."));
        },
        findImageForPsd: function(assets, psdFileName) {
            return assets.find(function (asset) {
                var fileName = (asset.file && asset.file.name.toLowerCase() || asset.name.toLowerCase());
                return fileName === psdFileName + ".png" || fileName === psdFileName + ".jpg"; }
            );
        }
    };
});
