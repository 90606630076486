angular
  .module("dell-repository.collection", [
    "ui.router",
    "taiPlaceholder",
    "ui.bootstrap",
    "dell-repository.deleteCollection",
    "dell-repository.helpers",
    "dell-repository.editFolder",
    "dell-repository.collectionShare"
  ])
  .config(function config($stateProvider) {
    $stateProvider.state("collection", {
      url: "/collection",
      views: {
        main: {
          controller: "CollectionCtrl",
          templateUrl: "collection/collection.tpl.html"
        }
      },
      data: {
        pageTitle: "DELL Retail Library"
      }
    });
  })
  .filter("bytes", function() {
    return function(bytes, precision) {
      if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) {
        return "-";
      }
      if (typeof precision == "undefined") {
        precision = 1;
      }
      var units = ["bytes", "kb", "mb", "gb", "tb", "pb"],
        number = Math.floor(Math.log(bytes) / Math.log(1024));
      return (
        (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) +
        " " +
        units[number]
      );
    };
  })
  .directive("downloadfile", function() {
    return {
      restrict: "A",
      link: function(scope, element) {
        element.bind("click", function(e) {
          angular
            .element()
            .find("a")
            .trigger("click");
        });
      }
    };
  })
  .controller("CollectionCtrl", function(
    $scope,
    $state,
    $http,
    AuthService,
    $uibModal,
    $log,
    $uibModalInstance,
    NotificationHelper,
    folderId,
    folderName
  ) {
    var downloadAttrSupported = "download" in document.createElement("a");
    $log.info("Download attribute supported: " + downloadAttrSupported);

    var allItems;
    $scope.itemsSelected = [];
    $scope.folderName = folderName;
    $scope.pageCollection = 1;
    $scope.disablePrevious = true;
    $scope.disableNext = true;
    $scope.res = [];
    var totalItems = 0;
    loadItems(1);

    $scope.downloadAll = function() {
      icounter = 0;
      myLoop();
    };

    var icounter = 0;
    function myLoop() {
      //  create a loop function
      if ($scope.itemsSelected.length > 0) {
        $scope.button = $scope.itemsSelected[0].downloadUrl;
        setTimeout(function() {
          if ($scope.itemsSelected.length > icounter) {
            $scope.button = $scope.itemsSelected[icounter].downloadUrl;
            addMetric($scope.itemsSelected[icounter].id, 1);
            document.getElementById("downloader").click();
            icounter++;
            myLoop();
          }
        }, 2000);
      }
    }

    var addMetric = function(content_id, type) {
      AuthService.addMetric(content_id, type).then(
        function() {},
        function(err) {}
      );
    };

    var reqListener = function() {
      if (f_name === "") {
        f_name = f_ref;
      }
      var blobObject = this.response;
      window.navigator.msSaveBlob(blobObject, f_name);
    };

    $scope.showCollectionShare = function() {
      var modalInstance = $uibModal.open({
        templateUrl: "folder/collectionShare/collectionShare.tpl.html",
        controller: "CollectionShareCtrl",
        scope: $scope,
        windowClass: "app-modal-window-assetShareUrl",
        backdropClass: "app-modal-BackDropWindow",
        resolve: {
          folderId: function() {
            return folderId;
          }
        }
      });

      modalInstance.result.then(
        function() {
          NotificationHelper.notify(
            "The collection has been shared.",
            "success",
            2500
          );
        },
        function() {}
      );
    };

    $scope.showEditFolder = function() {
      var modalInstance = $uibModal.open({
        templateUrl: "folder/editFolder/editFolder.tpl.html",
        controller: "EditFolderCtrl",
        scope: $scope,
        windowClass: "app-modal-window-addFolder",
        backdropClass: "app-modal-BackDropWindow",
        resolve: {
          folderId: function() {
            return folderId;
          },
          name: function() {
            return folderName;
          }
        }
      });

      modalInstance.result.then(
        function(name) {
          NotificationHelper.notify(
            "The collection has been edited.",
            "success",
            2500
          );
          folderName = name;
          $scope.folderName = name;
        },
        function() {}
      );
    };

    $scope.download = function(item) {
      $log.info("Download trigger " + id);
      var oReq1 = new XMLHttpRequest();
      oReq1.addEventListener("load", reqListener, false);
      oReq1.open("get", this, true);
      oReq1.responseType = "blob";
      oReq1.send();
    };

    $scope.toggleItemSelected = function(item) {
      var index = -1;
      for (var i = $scope.itemsSelected.length - 1; i >= 0; --i) {
        if ($scope.itemsSelected[i].id == item.id) {
          index = i;
        }
      }
      if (index !== -1) {
        item.isSelected = false;
        $scope.itemsSelected.splice(index, 1);
      } else {
        item.isSelected = true;
        $scope.itemsSelected.push(item);
      }
    };

    $scope.changePage = function(page, itemAmount) {
      $scope.items = [];
      $scope.pageCollection = page;
      var begins = $scope.pageCollection * itemAmount - itemAmount + 1;
      for (var i = begins; i < itemAmount * $scope.pageCollection + 1; i++) {
        if (i <= allItems.length) {
          $scope.items.push(allItems[i - 1]);
        }
      }
      if ($scope.pageCollection * itemAmount < allItems.length) {
        $scope.disableNext = false;
      } else {
        $scope.disableNext = true;
      }
      if ($scope.pageCollection == 1) {
        $scope.disablePrevious = true;
      } else {
        $scope.disablePrevious = false;
      }
    };

    $scope.nextPageItems = function(itemAmount) {
      if ($scope.pageCollection * itemAmount < allItems.length) {
        $scope.items = [];
        $scope.pageCollection++;
        var begins = $scope.pageCollection * itemAmount - itemAmount + 1;
        for (var i = begins; i < itemAmount * $scope.pageCollection + 1; i++) {
          if (i <= allItems.length) {
            $scope.items.push(allItems[i - 1]);
          }
        }
      }
      if ($scope.pageCollection * itemAmount < allItems.length) {
        $scope.disableNext = false;
      } else {
        $scope.disableNext = true;
      }
      if ($scope.pageCollection == 1) {
        $scope.disablePrevious = true;
      } else {
        $scope.disablePrevious = false;
      }
    };
    $scope.previousPageItems = function(itemAmount) {
      if ($scope.pageCollection > 1) {
        $scope.items = [];
        $scope.pageCollection--;
        var begins = $scope.pageCollection * itemAmount - itemAmount + 1;
        for (var i = begins; i < itemAmount * $scope.pageCollection + 1; i++) {
          if (i < allItems.length) {
            $scope.items.push(allItems[i - 1]);
          }
        }
      }
      if ($scope.pageCollection * itemAmount < allItems.length) {
        $scope.disableNext = false;
      } else {
        $scope.disableNext = true;
      }
      if ($scope.pageCollection == 1) {
        $scope.disablePrevious = true;
      } else {
        $scope.disablePrevious = false;
      }
    };
    function getAvailablePages() {
      $scope.res = [];
      for (i = 1; i <= $scope.pageAmount; i++) {
        $scope.res.push(i);
      }
    }
    function loadItems() {
      $scope.items = [];
      $scope.itemsSelected = [];
      AuthService.getCollection(folderId).then(
        function(data) {
          var counter = 0;
          allItems = data.collection;
          $scope.$parent.collectionCount = data.count;

          angular.forEach(allItems, function(value, key) {
            value.isSelected = false;
            if (counter < 8) {
              $scope.items.push(value);
            }
            counter++;
          });

          totalItems = data.count;
          $scope.pageAmount = Math.ceil(data.count / 8);
          //$scope.pageAmount = 2;
          getAvailablePages();
          $scope.changePage($scope.pageCollection, 8);
          if ($scope.pageCollection >= $scope.pageAmount) {
            $scope.disableNext = true;
          } else {
            $scope.disableNext = false;
          }
          if ($scope.pageCollection <= 1) {
            $scope.disablePrevious = true;
          } else {
            $scope.disablePrevious = false;
          }
        },
        function(err) {
          $log.info(err);
        }
      );
      if ($scope.pageCollection >= $scope.pageAmount) {
        $scope.disableNext = true;
      } else {
        $scope.disableNext = false;
      }
      if ($scope.pageCollection <= 1) {
        $scope.disablePrevious = true;
      } else {
        $scope.disablePrevious = false;
      }
    }

    //delete collection form
    $scope.showDeleteCollectionForm = function(idToDelete, asset) {
      if ($scope.itemsSelected.length > 0) {
        var modalInstance = $uibModal.open({
          templateUrl: "collection/deleteCollection/deleteCollection.tpl.html",
          controller: "DeleteCollectionCtrl",
          scope: $scope,
          windowClass: "app-modal-window-deleteUser",
          backdropClass: "app-modal-BackDropWindow",
          resolve: {
            folderId: function() {
              return folderId;
            },
            collectionToDelete: function() {
              return $scope.itemsSelected;
            }
          }
        });

        //deleteUsers result
        modalInstance.result.then(
          function() {
            angular.forEach($scope.$parent.items, function(value, key) {
              if (
                idToDelete === "*" ||
                include($scope.itemsSelected, value.id) ||
                idToDelete === value.assetId
              ) {
                $scope.$parent.items[key].isActive = false;
              }
            });

            if (idToDelete !== "*") {
              NotificationHelper.notify(
                "The asset(s) has been removed from your collection.",
                "info",
                2500
              );
              $scope.$parent.collectionCount =
                $scope.$parent.collectionCount - $scope.itemsSelected.length;
              if (
                (allItems.length - 1) % 8 === 0 &&
                $scope.pageCollection !== 1
              ) {
                $scope.pageCollection = $scope.pageCollection - 1;
              }
            } else {
              NotificationHelper.notify(
                "Your collection has been cleared.",
                "info",
                2500
              );
              $scope.$parent.collectionCount = 0;
              $scope.pageCollection = 1;
              $scope.pageAmount = 1;
              $scope.disablePrevious = true;
              $scope.disableNext = true;
            }
            if ($scope.items.length - 1 > 0) {
              loadItems($scope.pageCollection);
            } else {
              $scope.items = [];
            }

            var check = false;
            if (!!asset && !!asset.parentId && idToDelete !== "*") {
              angular.forEach(allItems, function(value, key) {
                if (
                  value.id !== asset.id &&
                  value.parentId === asset.parentId
                ) {
                  check = true;
                }
              });

              if (!check) {
                idToDelete = asset.parentId;
              }
            }

            function include(arr, id) {
              for (var i = 0; i < arr.length; i++) {
                if (arr[i].parentId === id) {
                  return true;
                }
              }
              return false;
            }
          },
          function() {
            $log.info("Cancelled");
          }
        );
      }
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss("cancel");
    };
    $scope.mediaFile = "";
    $scope.getFileUrl = function(element) {
      if (!element) {
        return false;
      }
      $scope.$apply(function(scope) {
        $scope.mediaFile = "";
        $scope.mediaFile = element.files[0];
      });
      AuthService.importCollection(folderId, $scope.mediaFile).then(
        function(data) {
          //$scope.pageCollection = 1;
          loadItems();
          //$scope.pageCollection = 1;
        },
        function(err) {
          $log.info(err);
        }
      );
    };
    $scope.exportCollectionUrl = AuthService.exportCollection(folderId);
    //	AuthService.exportCollection().then(function(data) {
    //		var anchor = angular.element('<a/>');
    //		anchor.attr({
    //		href: 'data:attachment/dalc;charset=utf-8,' + encodeURI(data),
    //		target: '_blank',
    //		download: 'collection.dalc'
    //		})[0].click();
    //		//loadItems();
    //	}, function(err) {
    //		$log.info(err);
    //	});
    //};
  });
