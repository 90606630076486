import addFolderTpl from './addFolder/addFolder.tpl.html';

angular.module('dell-repository.folder', ['ui.router', 'taiPlaceholder', 'ui.bootstrap', 'dell-repository.helpers','dell-repository.collection', 'dell-repository.addFolder', 'dell-repository.deleteFolder', 'dell-repository.editFolder', 'dell-repository.collectionShare']).config(function config($stateProvider) {
	$stateProvider.state('folder', {
		url : '/folder',
		views : {
			"login" : {
				controller : 'FolderCtrl',
				templateUrl : 'folder/folder.tpl.html'
			}
		},
		data : {
			pageTitle : 'Folder'
		}
	});
}).controller('FolderCtrl', function FolderCtrl($scope, $uibModalInstance, $timeout, AuthService, $log,$uibModal, NotificationHelper) {

	function loadFolders() {
	AuthService.getFolders().then(function(data) {
		$scope.isMultiple = true;
		$scope.data = data;
	}, function(err) {
		$log.info(err);
	});
	}

	loadFolders();

	$scope.cancel = function() {
		$uibModalInstance.dismiss('cancel');
	};

		//Show collection
		$scope.showCollection = function(folder) {
			var modalInstance = $uibModal.open({
				templateUrl : 'collection/collection.tpl.html',
				controller : 'CollectionCtrl',
				scope : $scope,
				windowClass : 'app-modal-window-collection',
				backdropClass : 'app-modal-BackDropWindow',
				resolve : {
					folderId : function() {
						return folder.id;
					},
					folderName : function() {
						return folder.name;
					}
				}
			});
		};

		$scope.showEditFolder = function(folder) {
			var modalInstance = $uibModal.open({
				templateUrl : 'folder/editFolder/editFolder.tpl.html',
				controller : 'EditFolderCtrl',
				scope : $scope,
				windowClass : 'app-modal-window-addFolder',
				backdropClass : 'app-modal-BackDropWindow',
				resolve : {
					folderId : function() {
						return folder.id;
					},
					name : function() {
						return folder.name;
					}
				}
			});

			//deleteFolder result
			modalInstance.result.then(function() {
				NotificationHelper.notify('The collection has been edited.', 'success', 2500);
				loadFolders();
			}, function() {
				$log.info('Cancelled');
			});
		};


		$scope.getTrimName = function(name) {
			if(name.length > 20)
			{
			name =	name.substring(0, 20) + '...';
			}
			return name;
		};

   //Show Share
	$scope.showCollectionShare = function(folder) {

		var modalInstance = $uibModal.open({
			templateUrl : 'folder/collectionShare/collectionShare.tpl.html',
			controller : 'CollectionShareCtrl',
			scope : $scope,
			windowClass : 'app-modal-window-assetShareUrl',
			backdropClass : 'app-modal-BackDropWindow',
			resolve : {
				folderId : function() {
					return folder.id;
				}
			}
		});

		modalInstance.result.then(function() {
			NotificationHelper.notify('The collection has been shared.', 'success', 2500);
		}, function() {

		});
	};

		$scope.showDeleteFolder = function(folderId) {
			var modalInstance = $uibModal.open({
				templateUrl : 'folder/remove/remove.tpl.html',
				controller : 'DeleteFolderCtrl',
				scope : $scope,
				windowClass : 'app-modal-window-deleteUser',
				backdropClass : 'app-modal-BackDropWindow',
				resolve : {
					folderId : function() {
						return folderId;
					}
				}
			});

			//deleteFolder result
			modalInstance.result.then(function() {
				NotificationHelper.notify('The collection has been removed.', 'success', 2500);
				loadFolders();
			}, function() {
				$log.info('Cancelled');
			});
		};
//Add collection form

$scope.showAddCollectionForm = function() {
	var modalInstance = $uibModal.open({
		template : addFolderTpl,
		controller : 'AddFolderCtrl',
		scope : $scope,
		windowClass : 'app-modal-window-addFolder',
		backdropClass : 'app-modal-BackDropWindow'
	});

	modalInstance.result.then(function() {
		loadFolders();
	}, function() {
		$log.info('Cancelled');
	});
};

});

