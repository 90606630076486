angular.module('constants', ['ui.router', 'ui.bootstrap']).constant('AUTH_EVENTS', {
	notAuthenticated: 'auth-not-authenticated',
	notAuthorized: 'auth-not-authorized',
	tokenExpired: 'auth-token-expired'
}).constant('USER_ROLES', {
	admin: 'admin',
	marketer: 'marketer',
	publisher: 'publisher'
}).constant('SCREENFILL', [{
	displayName: "Screenfill",
	name: "Screenfill",
	canFilter: false,
	id: 246,
	hidden: false,
	children: [
		{
			displayName: "Primary",
			name: "Primary",
			canFilter: true,
			id: "Primary",
			hidden: false,
			children: [],
			parent: "Screenfill"
		},
		{
			displayName: "Gaming",
			name: "Gaming",
			canFilter: true,
			id: "Gaming",
			hidden: false,
			children: [],
			parent: "Screenfill"
		},
		{
			displayName: "Microsoft",
			name: "Microsoft",
			canFilter: true,
			id: "Microsoft",
			hidden: false,
			children: [],
			parent: "Screenfill"
		},
		{
			displayName: "Brand",
			name: "Brand",
			canFilter: true,
			id: "Brand",
			hidden: false,
			children: [],
			parent: "Screenfill"
		}

	],
	parent: "Image",
	isChecked: true,
	model: false
}]).constant('IMAGE_EXTENSIONS', [
	'ras', 'bmp', 'djv', 'ief', 'xpm', 'art', 'xbm', 'png', 'pnm', 'crw', 'cr2', 'nef', 'jpeg',
	'gif', 'wbmp', 'pat', 'djvu', 'tiff', 'jng', 'cdr', 'cdt', 'ico', 'pbm', 'orf', 'svgz',
	'cpt', 'rgb', 'pgm', 'svg', 'xwd', 'jpx', 'jpe', 'jpf', 'jpg', 'jpm', 'pcx', 'jp2', 'tif',
	'ppm', 'erf', 'jpg2', 'psd'
// considering PSD as an image in some pieces of the app
]).constant('EXTENSION_TO_ICON', {
	'xls': 'xlsx',
	'xlt': 'xlsx',
	'xlm': 'xlsx',
	'xlsx': 'xlsx',
	'xlsm': 'xlsx',
	'xltx': 'xlsx',
	'xltm': 'xlsx',
	'xlsb': 'xlsx',
	'xla': 'xlsx',
	'xlam': 'xlsx',
	'xll': 'xlsx',
	'xlw': 'xlsx',
	'ppt': 'pptx',
	'pot': 'pptx',
	'pps': 'pptx',
	'pptx': 'pptx',
	'pptm': 'pptx',
	'potx': 'pptx',
	'potm': 'pptx',
	'ppam': 'pptx',
	'ppsx': 'pptx',
	'ppsm': 'pptx',
	'sldx': 'pptx',
	'sldm': 'pptx',
	'doc': 'docx',
	'dot': 'docx',
	'wbk': 'docx',
	'docx': 'docx',
	'docm': 'docx',
	'dotx': 'docx',
	'dotm': 'docx',
	'docb': 'docx',
	'eps': 'psd',
	'psd': 'psd',
	'pdd': 'psd',
	'psb': 'psd',
	'pdp': 'psd',
	'ai': 'ai',
	'ait': 'ai',
	'draw': 'ai',
	'line': 'ai',
	'dwg': 'ai',
	'dxf': 'ai',
	'pdf': 'pdf',
	'ras': 'image',
	'bmp': 'image',
	'djv': 'image',
	'ief': 'image',
	'xpm': 'image',
	'art': 'image',
	'xbm': 'image',
	'png': 'image',
	'pnm': 'image',
	'crw': 'image',
	'cr2': 'image',
	'nef': 'image',
	'jpeg': 'image',
	'gif': 'image',
	'wbmp': 'image',
	'pat': 'image',
	'djvu': 'image',
	'tiff': 'image',
	'jng': 'image',
	'cdr': 'image',
	'cdt': 'image',
	'ico': 'image',
	'pbm': 'image',
	'orf': 'image',
	'svgz': 'image',
	'cpt': 'image',
	'rgb': 'image',
	'pgm': 'image',
	'svg': 'image',
	'xwd': 'image',
	'jpx': 'image',
	'jpe': 'image',
	'jpf': 'image',
	'jpg': 'image',
	'jpm': 'image',
	'pcx': 'image',
	'jp2': 'image',
	'tif': 'image',
	'ppm': 'image',
	'erf': 'image',
	'jpg2': 'image',
	'mkv': 'video',
	'flv': 'video',
	'ogg': 'video',
	'avi': 'video',
	'mov': 'video',
	'wmv': 'video',
	'mp4': 'video',
	'mpg': 'video',
	'm4p': 'video',
	'm4v': 'video',
	'3gp': 'video',
	'zip': 'zip',
	'rar': 'zip',
	'gz': 'zip',
	'tgz': 'zip'
});

