/*** IMPORTS FROM imports-loader ***/
var angular = require("angular");

var angular=window.angular,ngModule;
try {ngModule=angular.module(["ng"])}
catch(e){ngModule=angular.module("ng",[])}
var v1="<div id=\"deleteUserWrap\" class=\"modal-body\">\n<span ng-click=\"cancel()\"><img src=\"/assets/images/admin/close_window.png\"/></span>\n<div id=\"deleteContentWrap\">\n<div class=\"titleContainer row\">\n<h3 id=\"addUserTitle\" class=\"dellTitle\">Delete this asset</h3>\n</div>\n<div class=\"subtitle\">\n<p>Are you sure you want to delete this asset from the Library?</p>\n<p>Once deleted it cannot be undone</p>\n</div>\n<div id=\"deleteUserAnswerContainer\" class=\"row\">\n<button id=\"cancelDeleteButton\" class=\"btn btn-primary button1\" ng-click=\"cancel()\">\nCancel\n</button>\n<button id=\"deleteButton\" class=\"btn btn-primary button1\" ng-click=\"ok2()\">\nYes, Delete\n</button>\n</div>\n</div>\n</div>";
var id1="library/deleteContent/deleteContent.tpl.html";
var inj=angular.element(window.document).injector();
if(inj){inj.get("$templateCache").put(id1,v1);}
else{ngModule.run(["$templateCache",function(c){c.put(id1,v1)}]);}
module.exports=v1;
