import libraryTpl from './library.tpl.html';
import folderTpl from '../folder/folder.tpl.html';
//
require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/library/addContent/addContent.tpl.html");

require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/library/addToFolder/addToFolder.tpl.html");

require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/library/assetShareUrl/assetShareUrl.tpl.html");

require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/library/deleteContent/deleteContent.tpl.html");

require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/library/editContent/editContent.tpl.html");

require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/library/email/email.tpl.html");

require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/library/filterTag/filterTag.tpl.html");

require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/library/getContent/getContent.tpl.html");

require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/library/imageMenu/menu.tpl.html");

require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/library/library.tpl.html");

require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/library/mainMenu/menu.tpl.html");

require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/library/addContent/addContent.tpl.html");

require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/library/addToFolder/addToFolder.tpl.html");

require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/library/assetShareUrl/assetShareUrl.tpl.html");

require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/library/deleteContent/deleteContent.tpl.html");

require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/library/editContent/editContent.tpl.html");

require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/library/email/email.tpl.html");

require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/library/filterTag/filterTag.tpl.html");

require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/library/getContent/getContent.tpl.html");

require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/library/imageMenu/menu.tpl.html");

require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/library/library.tpl.html");

require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/library/mainMenu/menu.tpl.html");

require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/library/addContent/addContent.tpl.html");

require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/library/addToFolder/addToFolder.tpl.html");

require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/library/assetShareUrl/assetShareUrl.tpl.html");

require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/library/deleteContent/deleteContent.tpl.html");

require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/library/editContent/editContent.tpl.html");

require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/library/email/email.tpl.html");

require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/library/filterTag/filterTag.tpl.html");

require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/library/getContent/getContent.tpl.html");

require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/library/imageMenu/menu.tpl.html");

require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/library/library.tpl.html");

require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/library/mainMenu/menu.tpl.html");


angular
  .module("dell-repository.library", [
    "ui.router",
    "taiPlaceholder",
    "ui.bootstrap",
    "ui.carousel",
    "infinite-scroll",
    "dell-repository.getContent",
    "dell-repository.folder",
    "dell-repository.addContent",
    "dell-repository.editContent",
    "dell-repository.deleteContent",
    "dell-repository.collection",
    "dell-repository.assetShareUrl",
    "dell-repository.helpers",
    "dell-repository.LogoutModalWindow",
    "dell-repository.addToFolder",
    "dell-repository.email",
    "dell-repository.shared"
  ])
  .config(function config($stateProvider, SCREENFILL) {
    $stateProvider.state("library", {
      url: "/library",
      views: {
        main: {
          controller: "LibraryCtrl",
          template: libraryTpl
        }
      },
      data: {
        pageTitle: "DELL Retail Library"
      }
    });
  })
  .filter("bytes", function() {
    return function(bytes, precision) {
      if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) {
        return "-";
      }
      if (typeof precision == "undefined") {
        precision = 1;
      }
      var units = ["bytes", "kb", "mb", "gb", "tb", "pb"],
        number = Math.floor(Math.log(bytes) / Math.log(1024));
      return (
        (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) +
        " " +
        units[number]
      );
    };
  })
  .controller("LibraryCtrl", function(
    $scope,
    AuthService,
    $uibModal,
    $log,
    NotificationHelper,
    $location,
    SCREENFILL
  ) {
    var amountOfItems = parseInt(window.innerHeight / 308, 10) * 4;
    // var activeFilters = [];

    $scope.filterActive = false;
    $scope.activeFilters = [];
    $scope.mapActiveFilters = new Map();
    $scope.isImageMenu = false;
    $scope.brandFilters = [];
    $scope.filterTagsBackup = null;

    $scope.sortoptions = [
      {
        value: "name",
        label: "Name"
      },
      {
        value: "type",
        label: "Type"
      },
      {
        value: "uploaddate",
        label: "Upload Date"
      },
      {
        value: "expirationdate",
        label: "Expiration Date"
      }
    ];

    $(document).ready(function() {
      $("#imgClose").click(function() {
        $("#global-nav").addClass("scrolled-nav");
        $("#search").addClass("searchTop");
        $("#mainContainerItems").addClass("marginContainer");
        $("#mainContainerItems").removeClass("notmarginContainer");
        $("#myCarousel2").hide();
        $("#myCarousel1").show();
      });

      $scope.showBanner = function() {
        $("#global-nav").removeClass("scrolled-nav");
        $("#search").removeClass("searchTop");
        $("#mainContainerItems").addClass("notmarginContainer");
        $("#mainContainerItems").removeClass("marginContainer");
        $(".menuHeader").css("overflow", "");
        $("#myCarousel2").show();
        $("#myCarousel1").hide();
      };

      $scope.searchFromBanner = function(searchText, searchType) {
        $scope.searchValue = "";
        if (searchType === "assetType" || searchType === "brand") {
          if (!document.getElementById(searchText).checked)
          {
            $scope.clearFilters();
            $scope.clickOnFilterButton(searchText);
          }
        }
        else {
          $scope.searchValue = searchText;
          $scope.searchByKeyPhrase();
        }
      };
    });

    function getSharedCode() {
      if ($location.search().contentId) {
        $scope.showGetContentDetail($location.search().contentId);
      }
    }

    function loadFolders() {
      AuthService.getFolders().then(
        function(data) {
          $scope.isMultiple = true;
          $scope.folders = data.folders;

          getSharedCode();
        },
        function(err) {
          $log.info(err);
        }
      );
    }

    loadFolders();

    $scope.sortDirectionImages = [
      {
        imgUrl: "sortascending.png",
        name: "asc",
        text: "Ascending"
      },
      {
        imgUrl: "sortdescending.png",
        name: "desc",
        text: "Decending"
      }
    ];

    $scope.sortDirection = $scope.sortDirectionImages[1];

    $scope.selectedSort = $scope.sortoptions[2];

    $scope.busy = false;
    $scope.filter1Selected = true;
    $scope.page = 1;
    $scope.disablePrevious = true;
    $scope.disableNext = true;
    $scope.pages = [];
    $scope.view = "grid";
    if (AuthService.role() == "marketer") {
      $scope.addContentAllowed = false;
      $scope.deleteContentAllowed = false;
      $scope.editContentAllowed = false;
    } else {
      $scope.editContentAllowed = true;
      $scope.addContentAllowed = true;
      $scope.deleteContentAllowed = true;
    }
    loadItems(1);

    $scope.toggleSortDirection = function() {
      if ($scope.sortDirection.name === $scope.sortDirectionImages[0].name) {
        $scope.sortDirection = $scope.sortDirectionImages[1];
      } else {
        $scope.sortDirection = $scope.sortDirectionImages[0];
      }
      $scope.search();
    };

    $scope.getAvailablePages = function() {
      var res = [];
      for (i = 1; i <= $scope.pageAmount; i++) {
        res.push(i);
      }
      return res;
    };
    $scope.changePage = function(page) {
      loadItems($scope.page);
    };
    $scope.nextPageContent = function() {
      alert("s");
      $scope.page++;
      loadItems($scope.page);
    };
    $scope.previousPageContent = function() {
      $scope.page--;
      loadItems($scope.page);
    };

    $scope.addMetric = function(content_id, type) {
      AuthService.addMetric(content_id, type).then(
        function() {},
        function(err) {}
      );
    };

    $scope.toggleToCollection = function(
      asset,
      isFromInternal,
      appliedElsewhere,
      folderId,
      options
    ) {
      if (!!$scope.folders && $scope.folders.length > 0 && isFromInternal) {
        $scope.showGetContentDetail(asset.id);
        return;
      }

      AuthService.addMetric(asset.id, 3).then(function() {}, function(err) {});

      if (!asset.isBlocked /*&& !asset.isActive*/) {
        asset.isBlocked = true;
        AuthService.addToCollection(folderId, asset.assetId || asset.id).then(
          function(data) {
            //asset.isActive = true;
            asset.isBlocked = false;
            //applyInLibrary(asset.isActive);
            NotificationHelper.notify(
              "The asset has been added to your collection.",
              "success",
              2500
            );
            //$scope.collectionCount = data.count;
          },
          function(err) {
            $log.info(err);
          }
        );
      } else if (!asset.isBlocked) {
        asset.isBlocked = true;
        AuthService.deleteCollection(folderId, asset.assetId || asset.id).then(
          function(authenticated) {
            //asset.isActive = false;
            asset.isBlocked = false;
            //applyInLibrary(asset.isActive);
            NotificationHelper.notify(
              "The asset has been removed from your collection.",
              "info",
              2500
            );
            //$scope.collectionCount--;
          },
          function(err) {
            $log.info(err);
          }
        );
      }
    };
    $scope.items = [];

    $scope.parseContentType = function (item) {
      return item.contentType.replace(/\s/g, '_');
    };

    function loadItems(page) {
      amountOfItems = parseInt(window.innerHeight / 308, 10) * 5;
      $scope.items = [];
      $scope.filterBlock = true;
      $scope.noItemMessage = false;
      AuthService.getContent(
        page,
        amountOfItems,
        $scope.filterActive,
        $scope.activeFilters,
        $scope.searchValue,
        $scope.selectedSort.value,
        $scope.sortDirection.name
      ).then(
        function(data) {
          $scope.filterBlock = false;

          if (
            ($scope.filterActive || $scope.searchValue) &&
            data.content.length === 0
          ) {
            $scope.noItemMessage = true;
          } else {
            $scope.noItemMessage = false;
          }

          $scope.items = data.content;

          $scope.pageAmount = Math.ceil(data.total / amountOfItems);
          if ($scope.page == $scope.pageAmount) {
            $scope.disableNext = true;
          } else {
            $scope.disableNext = false;
          }
          if ($scope.page == 1) {
            $scope.disablePrevious = true;
          } else {
            $scope.disablePrevious = false;
          }
          $scope.busy = false;
          $scope.$emit("assets:filtered");
        },
        function(err) {
          $log.info(err);
        }
      );
      if ($scope.page == $scope.pageAmount) {
        $scope.disableNext = true;
      } else {
        $scope.disableNext = false;
      }
      if ($scope.page == 1) {
        $scope.disablePrevious = true;
      } else {
        $scope.disablePrevious = false;
      }
    }

    $scope.scrollLoadContent = function() {
      if ($scope.page < $scope.pageAmount) {
        loadItemsScrolled($scope.page);
        $scope.page++;
      }
    };

    function loadItemsScrolled(page) {
      page++;
      amountOfItems = parseInt(window.innerHeight / 308, 10) * 4;
      if ($scope.page == $scope.pageAmount) {
        $scope.busy = false;
      }

      if ($scope.busy) {
        $scope.page--;

        return false;
      }

      $scope.busy = true;
      AuthService.getContent(
        page,
        amountOfItems,
        $scope.filterActive,
        $scope.activeFilters,
        $scope.searchValue,
        $scope.selectedSort.value,
        $scope.sortDirection.name
      ).then(
        function(data) {
          $scope.items = $scope.items.concat(data.content);
          $scope.pageAmount = Math.ceil(data.total / amountOfItems);
          if ($scope.page == $scope.pageAmount) {
            $scope.disableNext = true;
          } else {
            $scope.disableNext = false;
          }
          if ($scope.page == 1) {
            $scope.disablePrevious = true;
          } else {
            $scope.disablePrevious = false;
          }
          $scope.busy = false;
        },
        function(err) {
          $log.info(err);
          $scope.busy = false;
        }
      );
      if ($scope.page == $scope.pageAmount) {
        $scope.disableNext = true;
      } else {
        $scope.disableNext = false;
      }
      if ($scope.page == 1) {
        $scope.disablePrevious = true;
      } else {
        $scope.disablePrevious = false;
      }
    }

    //Show form to add content Template
    getFilterTags("p");

    function getFilterTags(type) {
      $scope.activeFilters = [];
      $scope.mapActiveFilters = new Map();
      $scope.filterActive = false;
      $scope.filterTags = {};
      $scope.filterTagsScope = [];

      AuthService.getFilterTags(type).then(
        function(data) {
          $scope.filterTags = data.tags;

          var setParent = function (tags, parentName) {
            for (var i = 0; i < tags.length; i++) {
              var f = tags[i];
              if (parentName) {
                f.parent = parentName;
              }
              if (f.children && f.children.length > 0) {
                setParent(f.children, f.name);
              }
            }
          };

          setParent($scope.filterTags);
          $scope.page = 1;
        },
        function(err) {
          $log.info(err);
        }
      );
    }

    $scope.getFilterTags = function(type) {
      getFilterTags(type);
    };

    $scope.sendEmail = function() {
      var modalInstance = $uibModal.open({
        templateUrl: "library/email/email.tpl.html",
        controller: "EmailCtrl",
        scope: $scope,
        windowClass: "app-modal-window-addContent",
        backdropClass: "app-modal-BackDropWindow"
      });
    };

    $scope.showFolders = function() {
      var modalInstance = $uibModal.open({
        template: folderTpl,
        controller: "FolderCtrl",
        scope: $scope,
        windowClass: "app-modal-window-folder",
        backdropClass: "app-modal-BackDropWindow"
      });
    };

    //Show get content form

    $scope.showGetContentDetail = function(sendId) {
      var modalInstance = $uibModal.open({
        templateUrl: "library/getContent/getContent.tpl.html",
        controller: "GetContentCtrl",
        scope: $scope,
        windowClass: "app-modal-window-getContent",
        backdropClass: "app-modal-BackDropWindow",
        resolve: {
          idToGet: function() {
            return sendId;
          },
          filters: function () {
            return $location.search();
          }
        }
      });

      modalInstance.result.then(
        function () {
          $location.url($location.path());
        },
        function () {
          $location.url($location.path());
        }
      );
    };

    //Show add to folder
    $scope.showaddToFolder = function(sendId) {
      var modalInstance = $uibModal.open({
        templateUrl: "library/addToFolder/addToFolder.tpl.html",
        controller: "AddToFolderCtrl",
        scope: $scope,
        windowClass: "app-modal-window-getContent",
        backdropClass: "app-modal-BackDropWindow",
        resolve: {
          idToGet: function() {
            return sendId;
          }
        }
      });
    };

    //Add content form

    $scope.showAddContentForm = function() {
      var modalInstance = $uibModal.open({
        templateUrl: "library/addContent/addContent.tpl.html",
        controller: "AddContentCtrl",
        scope: $scope,
        windowClass: "app-modal-window-addContent",
        backdropClass: "app-modal-BackDropWindow",
        resolve: {
          userForm: function() {}
        }
      });
      //AddUsers result
      modalInstance.result.then(
        function() {
          $scope.page = 1;
          $scope.items = [];
          loadItems($scope.page);
        },
        function() {
          $log.info("Cancelled");
        }
      );
    };
    //Show form to delete user Template

    $scope.showDeleteContentForm = function(asset) {
      var modalInstance = $uibModal.open({
        templateUrl: "library/deleteContent/deleteContent.tpl.html",
        controller: "DeleteContentCtrl",
        scope: $scope,
        windowClass: "app-modal-window-deleteUser",
        backdropClass: "app-modal-BackDropWindow",
        resolve: {
          contentToDelete: function() {
            return asset.id;
          }
        }
      });

      //deleteUsers result
      modalInstance.result.then(
        function() {
          NotificationHelper.notify(
            "The asset has been removed from the library.",
            "info",
            2500
          );
          /*if (asset.isActive) {
        setTimeout(function () {
          NotificationHelper.notify('The asset has been removed from your collection.', 'info', 2500);
        }, 1000);
        AuthService.getCollection("531e3498-7404-11e8-b817-9b8cc86ba764").then(function(data) {
          $scope.collectionCount = data.count;
        });
      }*/

          angular.forEach($scope.items, function(value, key) {
            if (value.id === asset.id) {
              $scope.items.splice(key, 1);
            }
          });
        },
        function() {
          $log.info("Cancelled");
        }
      );
    };
    //Show Share Asset Url
    $scope.showAssetShareUrl = function(downloadUrl) {
      var modalInstance = $uibModal.open({
        templateUrl: "library/assetShareUrl/assetShareUrl.tpl.html",
        controller: "assetShareUrlCtrl",
        scope: $scope,
        windowClass: "app-modal-window-assetShareUrl",
        backdropClass: "app-modal-BackDropWindow",
        resolve: {
          downloadUrl: function() {
            return downloadUrl;
          }
        }
      });
    };

    var isEditContentOpened = false;

    $scope.showEditAsset = function(sendId) {
      if (!isEditContentOpened) {
        isEditContentOpened = true;
        AuthService.getContentDetail(sendId).then(
          function(data) {
            $scope.itemData = data.tags;
            var tags = [];
            for (var i = 0; i < data.tags.length; i++) {
              tags.push(data.tags[i].toLowerCase());
            }
            data.content_id = sendId;
            $scope.selectedTags = tags;
            var modalInstance = $uibModal.open({
              templateUrl: "library/editContent/editContent.tpl.html",
              controller: "EditContentCtrl",
              scope: $scope,
              windowClass: "app-modal-window-addContent",
              backdropClass: "app-modal-BackDropWindow",
              resolve: {
                data: function() {
                  return data;
                }
              }
            });
            modalInstance.result.then(
              function() {
                isEditContentOpened = false;
                $scope.page = 1;
                $scope.items = [];
                loadItems($scope.page);
              },
              function() {
                isEditContentOpened = false;
                $log.info("Cancelled");
              }
            );
          },
          function(err) {
            $log.info(err);
          }
        );
      }
    };

    //Show collection
    $scope.showCollection = function() {
      var modalInstance = $uibModal.open({
        templateUrl: "collection/collection.tpl.html",
        controller: "CollectionCtrl",
        scope: $scope,
        windowClass: "app-modal-window-collection",
        backdropClass: "app-modal-BackDropWindow",
        resolve: {
          userForm: function() {}
        }
      });

      modalInstance.result.then(
        function() {},
        function(removed) {
          /*  if (removed === '*' || removed.length) {
        angular.forEach($scope.items, function (value, key) {
          if (removed === '*' || removed.indexOf(value.id) !== -1) {
            $scope.items[key].isActive = false;
          }
        });
      }*/
        }
      );
    };

    //Logout click

    $scope.logout1 = function() {
      var info = {
        title: "",
        message: ""
      };
      info.title = "Are you sure you want to log out?";
      info.firstMessage =
        "If you are on a personal computer make it easier next time by staying logged in.";
      info.secondMessage =
        "If you’re on a public computer, please log-out at the end of your session.";
      showModalWindowMessage(info);
    };

    function showModalWindowMessage(infoToShow) {
      var modalInstance = $uibModal.open({
        templateUrl: "logout/logoutModalWindow.tpl.html",
        controller: "LogoutModalWindowCtrl",
        scope: $scope,
        windowClass: "app-modal-window-modalWindow",
        backdropClass: "app-modal-BackDropWindow",
        resolve: {
          info: function() {
            return infoToShow;
          }
        }
      });
    }

    function addFilterFunction(filterToAdd, filterHeading) {
      $scope.filterActive = true;
      $scope.page = 1;
      if (!$scope.mapActiveFilters.has(filterHeading.displayName)) {
        $scope.mapActiveFilters.set(filterHeading.displayName, [filterToAdd.name]);
      }
      else {
        let existFilter = $scope.mapActiveFilters.get(filterHeading.displayName).filter(obj => {return obj === filterToAdd.name}).length > 0;
        if (!existFilter) {
          $scope.mapActiveFilters.get(filterHeading.displayName).push(filterToAdd.name);
        }

      }
    }

    function deleteFilterFunction(filterToRemove, filterHeading) {
      if ($scope.mapActiveFilters.has(filterHeading.displayName)) {
        let existFilter = $scope.mapActiveFilters.get(filterHeading.displayName).filter(obj => {return obj === filterToRemove.name}).length > 0;
        if (existFilter) {
          let indexToDelete = $scope.mapActiveFilters.get(filterHeading.displayName).indexOf(filterToRemove.name)
          $scope.mapActiveFilters.get(filterHeading.displayName).splice(indexToDelete, 1);
        }
      }
    }

    function generateFilterPayload() {
      $scope.activeFilters = { and: [] };
      for (const [key, filters] of $scope.mapActiveFilters.entries()) {
        var firstLevelActiveFilter = { and: [] };
        if (key === "image" ) {
          firstLevelActiveFilter.and.push({ v: key });
          if (filters.length > 0) {
            angular.forEach(filters, function (filter, index) {
              firstLevelActiveFilter.and.push({ v: filter });
            });
          }
        }
        else {
          angular.forEach(filters, function (filter, index) {
            firstLevelActiveFilter.and.push({ v: filter });
          });
        }
        $scope.activeFilters.and.push(firstLevelActiveFilter);
      }
    }

    $scope.searchTimeout = null;

    $scope.getThumbnail = function(item) {
      var exts = ["jpg", "jpeg", "png", "gif"],
        ext = item.downloadUrl.split(".");

      if (!!item.thumbnail) {
        return item.thumbnail;
      } else if (exts.indexOf(ext[ext.length - 1]) !== -1) {
        return item.downloadUrl;
      } else {
        return "assets/images/fileTypes/default_" + $scope.parseContentType(item) + ".png";
      }
    };

    $.fn.textWidth = function(text, font) {
      if (!$.fn.textWidth.fakeEl) {
        $.fn.textWidth.fakeEl = $("<span>")
          .hide()
          .appendTo(document.body);
      }
      $.fn.textWidth.fakeEl
        .text(text || this.val() || this.text())
        .css("font", font || this.css("font"));
      return $.fn.textWidth.fakeEl.width();
    };

    $scope.getDownloadUrl = function(downloadContent) {
      var downloadUrl = downloadContent;
      return downloadUrl;
    };

    $scope.backToMainFilter = function () {
      $scope.brandFilters = angular.copy($scope.filterTags.filter(function (tag) { return tag.name === "Brand"; }));
      var assetsFilter = $scope.filterTagsBackup;
      $scope.clearFilters();
      loadItems(1);
      $scope.fgaOrModel = null;
      $scope.filterTags = $scope.brandFilters.concat(assetsFilter);
      $scope.isImageMenu = false;
    };

    $scope.addFilterTag = function(filter, filterHeading, event, filterTags) {
      var ulFilter = $(event.target).siblings("ul.dropdown-menu");
      if (!$scope.filterTagsBackup) {
        $scope.filterTagsBackup = angular.copy($scope.filterTags.filter(function (tag) { return tag.name !== "Brand"; }));
      }

      // Always clear the page number
      $scope.page = 1;
      $scope.busy = true;

      if (filter.name === "Image") {
        var imageFilters = filter.children.filter(function (f) { return f.hidden === undefined; });

        $scope.brandFilters = angular.copy(filterTags.filter(function (tag) { return tag.name === "Brand"; }));
        $scope.isImageMenu = true;
        var newImageFilter = angular.copy(filter);
        newImageFilter.children = angular.copy(imageFilters);
        $scope.filterTags = [].concat($scope.brandFilters).concat(newImageFilter).concat(SCREENFILL)
                                .filter(function (f) { return f.name.toLowerCase() !== "color"; });
      }
      $("li", ulFilter)
        .last()
        .css({
          "border-bottom": "none"
        });
      if (
        $(event.target)
          .siblings("ul.dropdown-menu")
          .hasClass("show_subfilter")
      ) {
        $(event.target)
          .siblings("ul.dropdown-menu")
          .removeClass("show_subfilter");
      } else if ($(ulFilter).has("li").length !== 0) {
        $(event.target)
          .siblings("ul.dropdown-menu")
          .addClass("show_subfilter");
      }

      if (event.target.checked) {
        addFilterFunction(filter, filterHeading);
      }
      else {
        deleteFilterFunction(filter, filterHeading);
        // $scope.activeFilters = $scope.activeFilters.and.filter(item => item.v !== filter.name)
      }
      generateFilterPayload();
      loadItems(1);
      checkIfFilters();
    };

    //add SubFilter
    $scope.addSubFilterTag = function(subFilter, filter, event) {
      if (event.target.checked) {
        addFilterFunction(subFilter, { displayName: filter.name });
      }
      else {
        deleteFilterFunction(subFilter, { displayName: filter.name });
      }
      generateFilterPayload();
      loadItems(1);
      checkIfFilters();
      $scope.page = 1;
    };

    function checkIfFilters() {
      $scope.filterActive = true;
      if ($scope.activeFilters.length > 0) {
        $scope.filterActive = true;
      }
    }

    $scope.clearFilters = function() {
      $scope.page = 1;
      $scope.filterActive = false;
      $(".inputFather")
        .parent()
        .find("input[type=checkbox]")
        .prop("checked", false);
      $(".accordionDetail ul.dropdown-menu").removeClass("show_subfilter");
      $scope.activeFilters = [];
      $scope.mapActiveFilters = new Map();
      $scope.clearFilterModels();
    };

    $scope.clearAll = function() {
      $scope.clearFilters();
      $scope.busy = true;
      loadItems(1);
    };

    $scope.clearFilterModels = function(){
      angular.forEach($scope.filterTags, function(value, key) {
        var activeSecondLevelItems = value.children.filter(function(type) { return type.model === true; });
        angular.forEach(activeSecondLevelItems, function(secondLevelItem){
          var activeThirdLevelItems = secondLevelItem.children.filter(function(type) { return type.model === true; });
          secondLevelItem.model = false;
          angular.forEach(activeThirdLevelItems, function(thirdLevelItem, key) {
            thirdLevelItem.model = false;
          });
        });
      });
    };

    $scope.searchByKeyPhrase = function() {
      if($scope.searchTimeout != null){
        clearTimeout($scope.searchTimeout);
      }

      // Make a new timeout set to go off in 800ms
      $scope.searchTimeout = setTimeout(function () {
        $scope.filterActive = false;
        $scope.filterBlock = false;
        $scope.page = 1;
        $scope.busy = true;
        loadItems($scope.page);
      }, 800);
    };

    $scope.clickOnFilterButton = function(searchText) {
      $scope.busy = true;
      document.getElementById(searchText).click();
    };

    $scope.search = function() {
      $scope.busy = true;
      $scope.filterActive = false;
      $scope.filterBlock = false;
      $scope.page = 1;
      loadItems($scope.page);
    };

    $scope.searchFgaOrModel = function (input) {
      $scope.fgaOrModel = input.fgaOrModel;
      addFilterFunction();
    };

    $scope.filterColorClass = function(filter) {
      const COLORS = {
        'Toolkit': 'toolkitFilter',
        'Video': 'videoFilter',
        'Guidelines': 'guidelinesFilter',
        'RCAG': 'rcagFilter',
        'Image': 'imageFilter',
        'Templates': 'templateFilter',
      }
      const color = COLORS[filter.name] || "";
      return color;
    };
  });
