angular.module('dell-repository.helpers')
  .factory('QueryStringBuilder', function () {
    return {
      /**
       * Builds a query string from array of values
       *
       * @param {*} values array in { field: value } format
       * @returns a query string value format like field1=value1&field2=value2
       */
      build: function (values) {
        return values.filter(function (filter) {
          var key = Object.keys(filter)[0];

          return filter[key] !== '';
        }).map(function (filter) {
          var key = Object.keys(filter)[0];
          var value = Object.values(filter)[0];
          return key + '=' + value;
        }).join('&');
      },
      /**
       * Parse a querystring like value to an array of values.
       *
       * @param {*} queryString field1=value1&field2=value2
       * @returns an array of query string values like in [{ field: value }]
       */
      parse: function (queryString) {
        if (!!queryString) {
          return Object.keys(queryString).map(function (key) {
            var filter = {};
            filter[key] = queryString[key];
            return filter;
          });
        }

        return null;
      }
    };
  });