import usersTpl from './users.tpl.html';
import deleteUserTpl from './deleteUser/deleteUser.tpl.html';

angular.module('dell-repository.users', ['ui.router', 'taiPlaceholder', 'ui.bootstrap', 'dell-repository.addUser', 'dell-repository.deleteUser',
//THIRD PARTY MODULES
'treeControl', 'dell-repository.LogoutModalWindow']).config(function config($stateProvider, USER_ROLES) {
  $stateProvider.state('users', {
    url : '/users',
    views : {
      "main" : {
        controller : 'UsersCtrl',
        template : usersTpl
      }
    },
    data : {
      authorizedRoles : [USER_ROLES.admin],
      pageTitle : 'Manage Users'
    }
  });
}).controller('UsersCtrl', function($scope, $state, AuthService, $log, $uibModal, $location) {

  var totalUsers = 0;
  var amountOfUsers = parseInt(window.innerHeight*0.8/50, 10)-1;

  $scope.updateUser = false;
  $scope.page = 1;
  $scope.disablePrevious = true;
  $scope.disableNext = true;
  $scope.pages = [];

  //Load Users
  loadUsers($scope.page);
  function loadUsers(page) {
    $scope.users = [];
    if ($scope.page == $scope.pageAmount) {
      $scope.disableNext = true;
    } else {
      $scope.disableNext = false;

    }
    if ($scope.page == 1) {
      $scope.disablePrevious = true;
    } else {
      $scope.disablePrevious = false;

    }
    AuthService.getUsers(page,amountOfUsers).then(function(data) {
      $scope.users = data.users;
      angular.forEach($scope.users, function(value, key) {
        value.editUser = false;
        value.role = angular.uppercase(value.role.substring(0,1))+value.role.substring(1,value.role.length);
      });
      $scope.pageAmount = Math.ceil(data.total / amountOfUsers);
      totalUsers = data.total;
      if ($scope.page == $scope.pageAmount) {
        $scope.disableNext = true;
      } else {
        $scope.disableNext = false;

      }
      if ($scope.page == 1) {
        $scope.disablePrevious = true;
      } else {
        $scope.disablePrevious = false;

      }
    }, function(err) {
      $log.info(err);
    });
  }


  $scope.getAvailablePages = function() {
    var res = [];
    for ( var i = 1; i <= $scope.pageAmount; i++) {
      res.push(i);
    }
    return res;
  };
  $scope.changePage = function(page) {
    loadUsers($scope.page);
  };
  $scope.nextPageUsers = function() {
    $scope.page++;
    loadUsers($scope.page);
  };
  $scope.previousPageUsers = function(itemAmount) {
    $scope.page--;
    loadUsers($scope.page);
  };

  $scope.go = function(path) {
    $location.path(path);
  };

  //Logout click

  $scope.logout1 = function() {
    var info={"title":"", "message":""};
    info.title = "Are you sure you want to log out?";
    info.firstMessage = "If you are on a personal computer make it easier next time by staying logged in.";
    info.secondMessage = "If you’re on a public computer, please log-out at the end of your session.";
    showModalWindowMessage(info);
  };

  function showModalWindowMessage(infoToShow) {
    var modalInstance = $uibModal.open({
      templateUrl : 'logout/logoutModalWindow.tpl.html',
      controller : 'LogoutModalWindowCtrl',
      scope : $scope,
      windowClass : 'app-modal-window-modalWindow',
      backdropClass : 'app-modal-BackDropWindow',
      resolve : {
        info : function() {
          return infoToShow;
        }
      }
    });
  }

  //Show form to add user Template

  $scope.showAddUserForm = function() {
    var modalInstance = $uibModal.open({
      templateUrl : 'users/addUser/addUser.tpl.html',
      controller : 'AddUserCtrl',
      scope : $scope,
      windowClass : 'app-modal-window-addUser',
      backdropClass : 'app-modal-BackDropWindow',
      resolve : {
        userForm : function() {
          return $state.transitionTo("users");
        }
      }
    });
    //AddUsers result
    modalInstance.result.then(function() {
      loadUsers($scope.page);
    }, function() {
      $log.info('Cancelled');
    });

  };
  //Show form to delete user Template

  $scope.showDeleteUserForm = function(idToDelete) {
    var modalInstance = $uibModal.open({
      template : deleteUserTpl,
      controller : 'DeleteUserCtrl',
      scope : $scope,
      windowClass : 'app-modal-window-deleteUser',
      backdropClass : 'app-modal-BackDropWindow',
      resolve : {
        userToDelete : function() {
          return idToDelete;
        }
      }
    });

    //deleteUsers result
    modalInstance.result.then(function() {
      if((totalUsers-1)%amountOfUsers === 0 && $scope.page!==1){$scope.page=$scope.page-1;}
      loadUsers($scope.page);
    }, function() {
      $log.info('Cancelled');
    });
  };
  var roleTemp = "";
  var emailTemp = "";
  //Enable edit and disable others User
  $scope.enableEdit = function(user){
    angular.forEach($scope.users, function(value, key) {
      value.editUser = false;
    });
    roleTemp = user.role;
    emailTemp = user.email;
    user.editUser=true;
  };
  $scope.cancelEditUser = function(user){
    user.role = roleTemp;
    user.email = emailTemp;
    user.editUser=false;
  };
  //Update user with endpoint
  $scope.updateUser = function(user){
    $scope.inputError = false;
    AuthService.updateUser(user).then(function() {
      user.editUser=false;
      loadUsers($scope.page);
    }, function(err) {
      if(err=='Bad request'){
        $scope.inputError = true;
      }
      $log.info(err);
    });
  };
});
