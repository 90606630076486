angular.module('dell-repository.expired', ['ui.router', 'taiPlaceholder', 'ui.bootstrap']).config(function config($stateProvider) {
	$stateProvider.state('expired', {
		url : '/expired',
		views : {
			"main" : {
				controller : 'ExpiredCtrl',
				templateUrl : 'verification/expired/expired.tpl.html'
			}
		},
		data : {
			pageTitle : 'DELL Retail Library'
		}
	});
}).controller('ExpiredCtrl', function($scope, $state) {});
