/*** IMPORTS FROM imports-loader ***/
var angular = require("angular");

var angular=window.angular,ngModule;
try {ngModule=angular.module(["ng"])}
catch(e){ngModule=angular.module("ng",[])}
var v1="<header class=\"container no-padding header-admin\">\n<div id=\"logo\" class=\"col-xs-12 col-lg-5\">\n<a href=\"#\" onclick=\"window.location.reload();\"><img src=\"/assets/images/logo-top-2.png\" alt=\"Dell\"/>\n</a>\n</div>\n<div id=\"containerItemsMenu\" class=\"col-xs-12 col-lg-7\">\n<div class=\"ng-scope no-padding hidden-xs hidden-sm\">\n<div class=\"subMenuItems no-padding retail-name-container\">\n<p class=\"hidden-xs retail-name\">\nRetail Asset Library\n</p>\n</div>\n</div>\n</div>\n</header>\n<div class=\"row clearLogo\">\n<h3 class=\"dellTitle\">What would you like to do?</h3>\n<div id=\"tileContainer\">\n<div class=\"col-md-4\"></div>\n<a href ui-sref=\"users\">\n<div class=\"col-md-2\">\n<div class=\"dellTile searchType\" ng-click=\"ch1=selectedTile(1)\" ng-class=\"{'selectedTile': ch1}\">\n<div>\n<img src=\"/assets/images/admin/admin_tile.png\"/>\n<p>\nADMIN\n</p>\n</div>\n</div>\n</div></a>\n<a href ui-sref=\"library\">\n<div class=\"col-md-2\">\n<div class=\"dellTile searchType\" ng-click=\"ch2=selectedTile(2)\" ng-class=\"{'selectedTile': ch2}\">\n<div>\n<img src=\"/assets/images/admin/cloud_tile.png\"/>\n<p>\nPUBLISH<br>/DOWNLOAD\n</p>\n</div>\n</div>\n</div></a>\n<div class=\"col-md-4\"></div>\n</div>\n</div>\n<footer class=\"footer\">\n<div class=\"container text-center\">\n<div class=\"row\">\n<p>\n&copy;DELL 2018\n</p>\n</div>\n</div>\n</footer>\n";
var id1="app/admin/admin.tpl.html";
var inj=angular.element(window.document).injector();
if(inj){inj.get("$templateCache").put(id1,v1);}
else{ngModule.run(["$templateCache",function(c){c.put(id1,v1)}]);}
module.exports=v1;
