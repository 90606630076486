angular.module('dell-repository.findContent', ['ui.router', 'taiPlaceholder', 'ui.bootstrap']).config(function config($stateProvider) {
	$stateProvider.state('findContent', {
		url : '/findContent',
		views : {
			"main" : {
				controller : 'FindContentCtrl',
				templateUrl : 'findContent/findContent.tpl.html'
			}
		},
		data : {
			pageTitle : 'What is It?'
		}
	});
}).controller('FindContentCtrl', function($scope, $state, $http, $window, AuthService, searchable, $log) {

	//Manage selection
	$scope.selectedType = 0;
	$scope.selectedLanguage = "";
	$scope.canSearch = 0;
	$scope.search = function() {
		$state.go('repository', {}, {
			reload : true
		});
	};

	//Regions for the search

	//getCountries();

	function getCountries() {
		AuthService.getCountries().then(function(countries) {
			$scope.lanRegs = [];
			angular.forEach(countries, function(value, key) {
				$scope.lanRegs.push({
					'value' : value,
					'label' : key
				});
			});
		}, function(err) {
			$log.info(err);
		});
	}

	//Check which tile was selected and store its value in 'selectedType'

	$scope.selectedTile = function(tile) {
		$scope.selectedType = tile;
		$scope.ch1 = 0;
		$scope.ch2 = 0;
		$scope.ch3 = 0;
		$scope.ch4 = 0;
		$scope.ch5 = 0;

		//Call function that verifies if type and region were selected

		$scope.canSearch = searchable.searchNow($scope.selectedType, $scope.selectedLanguage);
		return 1;
	};

	//Check wen the language/region changes and store the value in 'selectedLanguage'

	$scope.changedRegion = function(item) {
		if (item == null) {
			$scope.canSearch = false;
			$scope.selectedLanguage = item;
		} else {
			$scope.selectedLanguage = item.value;
			$scope.canSearch = searchable.searchNow($scope.selectedType, $scope.selectedLanguage);
		}
	};

});
