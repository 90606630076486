//
require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/admin/admin.tpl.html");

require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/admin/admin.tpl.html");

require("imports-loader?angular!C:/dev/dell/retail-library-frontend/src/app/admin/admin.tpl.html");

import adminTpl from './admin.tpl.html';

angular.module('dell-repository.admin', ['ui.router', 'taiPlaceholder', 'ui.bootstrap'])
.config(function config($stateProvider,USER_ROLES) {
	$stateProvider.state('admin', {
		url : '/admin',
		views : {
			"main" : {
				controller : 'AdminCtrl',
				template : adminTpl
			}
		},
		data : {
      authorizedRoles: [USER_ROLES.admin],		
			pageTitle : 'Admin Start'
		}
	});
}).controller('AdminCtrl', function($scope, $state, $http, $window, AuthService, $log) {

	$scope.$emit('trackDataEvent','sessionAdmin');
	
	//Logout click
	$scope.logout1 = function() {
		AuthService.logout().then(function(authenticated) {
			$state.go('login', {}, {
				reload : true
			});
		}, function(err) {
			$log.info(err);
		});
	};
});
