import confirmModalTpl from './../../shared/confirmModal.tpl.html';

angular.module('dell-repository.editContent', ['ui.router', 'taiPlaceholder', 'ui.bootstrap', 'dell-repository.editContent', 'dell-repository.filterTag', 'dell-repository.helpers']).config(function config($stateProvider, USER_ROLES) {
    $stateProvider.state('editContent', {
        url: '/editContent',
        views: {
            "login": {
                controller: 'EditContentCtrl',
                templateUrl: 'editContent/editContent.tpl.html'
            }
        },
        data: {
            authorizedRoles: [USER_ROLES.admin, USER_ROLES.publisher],
            pageTitle: 'Edit Asset'
        }
    });
}).controller('EditContentCtrl', function EditContentCtrl($scope, $uibModalInstance, $timeout, AuthService, $log, $window, data, SCREENFILL, IMAGE_EXTENSIONS, $uibModal, SharedFunctionsService) {

    $scope.data = { // holds data that can be retrieved via simple ng-model
        content: [], // holds all the selected files
        contentDetails: [],
        thumbnail: [],
        name: '',
        license: '',
        hasNoExpiration: false,
        description: '',
        day: null,
        month: null,
        year: null
    };

    var deletedFiles = [];

    $scope.selectedFilters = "Please select filter tags"; // text for the tags filter select
    $scope.assetRoute = "Click to select one or more files";
    $scope.types = {
        list: ['campaign', 'digital activation', 'guidelines', 'image', 'rplg', 'templates', 'video'],
        selected: null
    }; // content types list
    $scope.tags = {list: [], selected: []}; // tags list
    $scope.screens = {list: {first: 1, second: 2, success: 3}, selected: 1}; // possible screens of the modal
    $scope.screenfills = SCREENFILL[0].children.map(function (screen) {
        return screen.displayName;
    });

    $scope.calendar = { // used for
        days: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
        months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        years: ["2016", "2017", "2018", "2019", "2020", "2021", "2022", "2023", "2024", "2025", "2026"]
    };

    $scope.control = {
        files: false, // specifies whether the list of all the selected files should be displayed
        filters: false, // specifies whether the filter select should be expanded
        uploading: false // true when the user clicked on "Upload" and validation passed
    };

    $scope.error = {}; // controls for validation of each field
    $scope.thumbnailType = { // controls which thumbnail option should be used
        useDefaultThumbnail: true,
        useCustomThumbnail: false
    };

    $scope.underscoreText = function (text) {
        return text.replace(/\s/g, '_');
    };

    $scope.confirmModalMessage = "You added PSD file(s) without a corresponding PNG or JPG image. Unless an image is included, no preview will appear for the PSD. Please confirm if you want to proceed.";

    var init = function () {

        $scope.isMultiple = data.assets.length > 1 ? true : false;

        $scope.data.name = data.contentName;
        $scope.data.description = data.description;

        $scope.types.selected = data.contentType;
        $scope.tags.selected = data.tags;
        $scope.data.license = data.license ? data.license : '';
        if (!!data.licenseExpirationFormat) {
            var contentExpDate = new Date(data.licenseExpirationFormat);

            var day = contentExpDate.getUTCDate();
            $scope.data.day = $scope.calendar.days[day - 1];
            var month = contentExpDate.getUTCMonth();
            $scope.data.month = $scope.calendar.months[month];

            var year = contentExpDate.getUTCFullYear();

            var yearItem = $scope.calendar.years.find(function (element) {
                return element === year.toString();
            });
            $scope.data.year = yearItem;
        } else {
            $scope.data.hasNoExpiration = true;
        }

        angular.forEach(data.assets, function (value, key) {
            var newItem = {
                id: value.id,
                name: value.name,
                type: value.type,
                isNew: false,
                downloadUrl: value.downloadUrl
            };
            var wrapper = {"type": 0, "file": newItem, "pinned": value.pinned};
            // let's pass wrapper as we require the file item
            if (isImage(newItem)) {
                wrapper["color"] = value.color;
                wrapper["details"] = value.details;
                wrapper["screenfill"] = value.screenfill;
                wrapper["type"] = 1;
            }
            $scope.data.content.push(wrapper);
        });

        if (!!data.thumbnail) {
            $scope.thumbnailType.useCustomThumbnail = true;
            $scope.thumbnailType.useDefaultThumbnail = false;
            $scope.imagePreview = data.thumbnail;
        } else {
            $scope.imagePreview = data.downloadUrl;
        }

        if ($scope.tags.selected.length) {
            $scope.selectedFilters = $scope.tags.selected.length + " filter tag(s) selected";
            $scope.error.tags = false;
        } else {
            $scope.selectedFilters = "Please select filter tags";
        }
    };

    // Get the tags list from the service
    AuthService.getFilterTags('a').then(function (data) {
        $scope.tags.list = data.tags;
    }, function (err) {
        $log.info(err);
    });

    // Cancel action for the model
    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

    // Confirm action on the success screen
    $scope.ok2 = function () {
        $uibModalInstance.close('success');
    };

    // Change the screen when validated or trying to go back
    $scope.changeScreen = function (back) {
        if (back) {
            $scope.screens.selected--;
        } else if ($scope.validate($scope.screens.selected)) {
            var missingImageForPsd = false;
            for (var i = 0, len = $scope.data.content.length; i < len; i++) {
                var item = $scope.data.content[i];
                if (SharedFunctionsService.getExtension(item.file.name).toLowerCase() === "psd") {
                    var imageForPsd = SharedFunctionsService.findImageForPsd($scope.data.content, SharedFunctionsService.getFileName(item.file.name));
                    if (!imageForPsd) {
                        missingImageForPsd = true;
                        break;
                    }
                }
            }
            if (missingImageForPsd) {
                var modalInstance = $uibModal.open({
                    template: confirmModalTpl,
                    controller: "ConfirmModalCtrl",
                    scope: $scope,
                    keyboard: false,
                    backdrop: 'static'
                });
                modalInstance.result.then(function() {
                    $scope.screens.selected++;
                });
            } else {
                $scope.screens.selected++;
            }
        }
    };

    $scope.imagesDetailsValidation = function () {
        for (var i = 0, len = $scope.data.content.length; i < len; i++) {
            var item = $scope.data.content[i];
            if (item['type'] === 1 && (item['color'] && !item['color'].match(/^[0-9 a-z]+$/i))) {
                return false;
            }
        }
        return true;
    };

    // Validates the form depending on the current screen
    $scope.validate = function (screen) {
        var error = false;

        $.each($scope.error, function (index, val) {
            $scope.error[index] = false;
        });

        if (screen === $scope.screens.list.first) {
            $scope.error.contentName = !$scope.data.name;
            $scope.error.file = !$scope.data.content.length || !$scope.imagesDetailsValidation();
            $scope.error.type = !$scope.types.selected;
            $scope.error.thumbnail = $scope.thumbnailType.useCustomThumbnail && (!$scope.data.thumbnail.length && !$scope.imagePreview);
            $scope.error.tags = !$scope.tags.selected.length;

        } else if (screen === $scope.screens.list.second) {
            $scope.error.description = !$scope.data.description;
            $scope.error.expiration = !$scope.data.hasNoExpiration && (!$scope.data.month || !$scope.data.day || !$scope.data.year) ? true : false;
        }

        $.each($scope.error, function (index, val) {
            error = val ? true : error;
        });

        return !error;
    };

    // Fired when the content file input changes its state
    $scope.contentChange = function (files) {
        $.each(files, function (index, value) {
            var fileAlreadyAdded = $scope.data.content.find(function (element) {
                return element.file.name === value.name && element.file.size === value.size;
            });
            if (!fileAlreadyAdded) {
                var wrapper = {"type": 0, "file": value, "pinned": false, "isNew": true};
                if (isImage(value)) {
                    wrapper["color"] = "";
                    wrapper["details"] = "";
                    wrapper["screenfill"] = "";
                    wrapper["type"] = 1;
                }
                $scope.data.content.push(wrapper);
            }
        });

        $scope.error.file = $scope.data.content.length ? false : $scope.error.file;
        $scope.assetRoute = $scope.data.content.length + ' file(s) selected - click to add more';
        $scope.setThumbnailImg();
    };

    $scope.pinFile = function (index) {
        $scope.data.content[index].pinned = !$scope.data.content[index].pinned;
    };

    // Fired when thumbnail file input changes its state
    $scope.thumbnailChange = function (files) {
        $scope.data.thumbnail = files;
        $scope.error.thumbnail = $scope.data.thumbnail.length ? false : $scope.error.thumbnail;
        $scope.setThumbnailImg();
    };

    // Sets the thumbnail image based on the selected files and options
    $scope.setThumbnailImg = function () {
        if ($scope.thumbnailType.useCustomThumbnail) {
            if(!$scope.imagePreview && (data.thumbnail || $scope.data.thumbnail)){
                if (data.thumbnail && data.thumbnail.length > 0 && $scope.data.thumbnail.length === 0) {
                    $scope.imagePreview = data.thumbnail;
                } else if ($scope.data.thumbnail.length === 1 && isImage($scope.data.thumbnail[0])) {
                    AuthService.readAsDataUrl($scope.data.thumbnail[0], $scope).then(function (result) {
                        $scope.imagePreview = result;
                    });
                    $scope.error.thumbnail = false;
                } else {
                    $scope.data.thumbnail = [];
                    $scope.imagePreview = false;
                }
            }
        } else if ($scope.data.content.length === 1 && isImage($scope.data.content[0])) {
            if (!$scope.data.content[0].isNew) {
                $scope.imagePreview = $scope.data.content[0].downloadUrl;
            } else {
                AuthService.readAsDataUrl($scope.data.content[0], $scope).then(function (result) {
                    $scope.imagePreview = result;
                });
            }

        } else if (!!$scope.types.selected) {
            $scope.imagePreview = 'assets/images/fileTypes/default_' + $scope.types.selected + '.png';
        } else {
            $scope.imagePreview = false;
        }
    };

    // Fired when the user chooses the default thumbnail option
    $scope.defaultThumbnailChange = function () {
        $scope.error.thumbnail = false;
        $scope.data.thumbnail = [];
        $scope.thumbnailType.useCustomThumbnail = !$scope.thumbnailType.useDefaultThumbnail;
        $scope.setThumbnailImg();
    };

    // Fired when the user chooses the custom thumbnail option
    $scope.customThumbnailChange = function () {
        $scope.thumbnailType.useDefaultThumbnail = !$scope.thumbnailType.useCustomThumbnail;
        $scope.setThumbnailImg();
    };

    $scope.discardFile = function (index) {
        var file = $scope.data.content[index];
        if (!file.isNew) {
            // Push the id of the file
            deletedFiles.push(file.file.id);
        }
        $scope.data.content.splice(index, 1);
        $scope.setThumbnailImg();
    };

    // Toggles the filter select tags
    $scope.toggle = function () {
        $('#addContentWrap .filterSelector').scrollTop(0);
        $scope.control.filters = !$scope.control.filters;
        $scope.setSelectedTags();
    };

    // Sets the selected tags value based on checked inputs and updates state variables
    $scope.setSelectedTags = function () {
        $scope.tags.selected = [];
        $('.filterSelector input:checked').each(function () {
            $scope.tags.selected.push(this.name);
        });

        setTimeout(function () {
            $scope.$apply(function () {
                if ($scope.tags.selected.length) {
                    $scope.selectedFilters = $scope.tags.selected.length + " filter tag(s) selected";
                    $scope.error.tags = false;
                } else {
                    $scope.selectedFilters = "Please select filter tags";
                }
            });
        }, 10);
    };

    $scope.autocompleteInformation = function (tagCategory, value) {
        return AuthService.getAutocompleteInformation(tagCategory, value).then(
            function (data) {
                return data;
            });
    };

    // When the entire form is validated and is correct the data is passed to the AuthService
    $scope.editContent = function () {
        if ($scope.control.uploading || !$scope.validate($scope.screens.selected)) {
            return;
        }

        // Split between files and the additional details items
        var files = [];
        var additionalDetails = [];
        for (var i = 0, len = $scope.data.content.length; i < len; i++) {
            var item = $scope.data.content[i];
            files[i] = item.file;
            item.file = files[i].name;
            item.color = !!item.color ? item.color.toLowerCase() : item.color;
            additionalDetails[i] = item;
        }
        $scope.busy = true;
        var payload = {
            content: files,
            content_id: data.content_id,
            contentDetails: additionalDetails,
            name: $scope.data.name,
            description: $scope.data.description,
            license: $scope.data.license,
            tags: $scope.tags.selected.join(','),
            useOldThumbnail: !!$scope.thumbnailType.useCustomThumbnail && !!$scope.imagePreview && $scope.data.thumbnail.length === 0,
            thumbnail: $scope.data.thumbnail.length && $scope.thumbnailType.useCustomThumbnail ? $scope.data.thumbnail[0] : null,
            type: $scope.types.selected,
            hasNoExpiration: $scope.data.hasNoExpiration,
            deletedFiles: deletedFiles.length > 0 ? deletedFiles.join(",") : ''
        };
        if (!$scope.data.hasNoExpiration) {
            var month = ($scope.calendar.months.indexOf($scope.data.month) + 1).toString();
            var day = $scope.data.day;
            month = month.length > 1 ? month : '0' + month;
            day = day.length > 1 ? day : '0' + day;

            payload.licenseExpiration = day + month + $scope.data.year;
        }

        $('html, body').css('cursor', 'wait');
        $scope.control.uploading = true;
        AuthService.editContent(payload).then(function (authenticated) {
            $scope.screens.selected = $scope.screens.list.success;
            $scope.control.uploading = false;
            $('html,body').css('cursor', 'default');
        }, function (err) {
            $log.info(err);
            $('html, body').css('cursor', 'default');
        });
    };

    // Helper function - says whether a file is a correct image
    var isImage = function (file) {
        if (!file || !file.name) {
            return false;
        }
        var fileExt = file.name.split('.');
        var extensions = IMAGE_EXTENSIONS;
        return extensions.indexOf(fileExt[fileExt.length - 1]) !== -1;
    };

    init();
});
