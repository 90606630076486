angular.module('dell-repository.collectionShare', ['ui.router', 'taiPlaceholder', 'ui.bootstrap']).config(function config($stateProvider) {
	$stateProvider.state('collectionShare', {
		url : '/collectionShare',
		views : {
			"login" : {
				controller : 'CollectionShareCtrl',
				templateUrl : 'collectionShare/collectionShare.tpl.html'
			}
		},
		data : {
			pageTitle : 'Share Asset'
		}
	});
}).controller('CollectionShareCtrl', function CollectionShareCtrl($scope, $uibModalInstance, $timeout, AuthService, $log, $window, folderId, focus) {
	$scope.inputError = false;
	$scope.data = {name: ''};
	$scope.cancel = function() {
		$uibModalInstance.dismiss('cancel');
	};
	var isSharing = false;

	focus('focusMe');

	$scope.share = function(data) {
		if (data.name && !isSharing) {
			isSharing = true;
			AuthService.shareFolder(data.name, folderId).then(function() {
				$scope.inputError = false;
				$uibModalInstance.close('success');
			}, function(err) {
				isSharing = false;
				$scope.inputError = true;
			});
	}
	};

});

