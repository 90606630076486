import 'angular'; 
import 'ng-infinite-scroll';
import '@uirouter/angularjs';
import 'angularjs-ui-bootstrap';
import 'angular-placeholder';
import 'angular-cookies';
import 'angular-ui-carousel';
import 'angular-tree-control';
import 'angular-tree-control/context-menu.js';
import 'keen-js';
import 'jquery';
import 'dotdotdot-js';

import './constants.js'
import './admin/admin.js';
import './findContent/findContent.js';
import './helpers/index.ts';
import './login/login.js';
import './login/recoverPassword/recoverPassword.js'
import './login/modalWindow/modalWindow.js';
import './login/landingCarousel/landingCarousel.js';
import './logout/logoutModalWindow.js'
import './shared/sharedModule.js';
import './library/getContent/getContent.js';
import './library/addContent/addContent.js';
import './library/filterTag/filterTag.js';
import './library/editContent/editContent.js';
import './library/deleteContent/deleteContent.js';
import './library/assetShareUrl/assetShareUrl.js';
import './library/addToFolder/addToFolder.js';
import './library/email/email.js';
import './library/library.js'
import './collection/collection.js';
import './folder/addFolder/addFolder.js'
import './folder/remove/removeFolder.js'
import './folder/collectionShare/collectionShare.js'
import './collection/deleteCollection/deleteCollection.js';
import './folder/editFolder/editFolder.js';
import './folder/folder.js';
import './users/users.js';
import './users/addUser/addUser.js';
import './users/deleteUser/deleteUser.js';
import './privacy/privacy.js';
import './verification/verification.js';
import './verification/expired/expired.js'
import './verification/error/error.js';
import './verification/confirmation/confirmation.js';
import './error/unsupportedbrowser.js'
import './app.js';
import './services.js';

import '../../node_modules/bootstrap/dist/js/bootstrap.min.js';
import '../less/main.less';
