import loginTemplate from './login.tpl.html';
import modalWindow from './modalWindow/modalWindow.tpl.html';

angular.module('dell-repository.login',
	['ui.router',
	'taiPlaceholder',
	'ui.bootstrap',
	'dell-repository.recoverPassword',
	'dell-repository.modalWindow',
	'dell-repository.landingCarousel'
]).config(function config($stateProvider) {
	$stateProvider.state('login', {
		url : '/login',
		views : {
			"main" : {
				controller : 'LoginCtrl',
				template : loginTemplate
			}
		},
		data : {
			pageTitle : 'Dell Retail Library Login'
		}
		
	});
}).controller('LoginCtrl', function LoginCtrl($scope, $uibModal, $log, $state, $uibModalStack, AuthService, $window) {
	$uibModalStack.dismissAll();
	//Store username and password
	$scope.data = {};
	$scope.date = new Date();
	$scope.login1 = function(data, valid,formElement) {
		$scope.loginErrorMessage = !valid;
		if (valid) {
			var info={"title":"", "message":""};
			AuthService.loginMail(data.username).then(function(authenticated) {
				info.title = "New login link created!";
				info.message = "A new login link* has been sent to your email. Check your inbox and click on it to come back and start using the Library.";
				showModalWindowMessage(info);
				/*if (AuthService.role() == 'admin') {
					$state.go('admin', {}, {
						reload : true
					});
				} else {
					$state.go('repository', {}, {
						reload : true
					});
				}*/
			}, function(err) {
				info.title = "Access not authorized";
				info.message = "Sorry – we don’t seem to recognize your email address. Please contact your local Dell marketer to gain access to the Dell Retail Asset Library.";
				showModalWindowMessage(info);
			});
		}
	};

	//Show ForgotPassword Template

	$scope.showForgotPasswordForm = function() {
		var modalInstance = $uibModal.open({
			templateUrl : 'login/recoverPassword/recoverPassword.tpl.html',
			controller : 'RecoverPasswordCtrl',
			scope : $scope,
			windowClass : 'app-modal-window',
			backdropClass : 'app-modal-BackDropWindow',
			resolve : {
				userForm : function() {
					return $scope.userForm;
				}
			}
		});
	};

	//Show ForgotPassword Template

	function showModalWindowMessage(infoToShow) {
		var modalInstance = $uibModal.open({
			template: modalWindow,
			controller : 'ModalWindowCtrl',
			scope : $scope,
			windowClass : 'app-modal-window-modalWindow',
			backdropClass : 'app-modal-BackDropWindow',
			resolve : {
				info : function() {
					return infoToShow;
				}
			}
		});
	}

	$scope.text = 'me@example.com';
	$scope.email = /^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/;

}).directive('pageTitle', [
    '$rootScope',
    '$timeout',
    '$transitions',
    function($rootScope, $timeout,$transitions) {
        return {
            restrict: 'A',
            link: function() {
                var listener = function($transitions) {
                    var default_title = "Login";
                    $timeout(function() {
                        	$rootScope.page_title = ($transitions.$to().data && $transitions.$to().data.pageTitle)
                            ? $transitions.$to().data.pageTitle : default_title;
                    });
                };
                $transitions.onSuccess({ }, listener);
            }
        }
    }
]);
