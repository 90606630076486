angular.module( 'dell-repository.LogoutModalWindow', [
  'ui.router',
  'taiPlaceholder',
  'ui.bootstrap'
])

.config(function config( $stateProvider) {
  $stateProvider.state( 'logoutModalWindow', {
    url: '/logoutModalWindow',
    views: {
      "logout": {
        controller: 'LogoutModalWindowCtrl',
        templateUrl: 'logout/logoutModalWindow.tpl.html'
      }
    }
  });
})

.controller( 'LogoutModalWindowCtrl', function LogoutModalWindowCtrl($scope,AuthService,$state,$log,$uibModalInstance,info,$timeout) {
	$scope.data = {};
	$scope.data.title = info.title;
	$scope.data.firstMessage = info.firstMessage;
  $scope.data.secondMessage = info.secondMessage;
	if(info.timer){
		$timeout(autoDismiss, 3000);
	}
	function autoDismiss() {
		$uibModalInstance.dismiss('cancel');
	}
	$scope.cancel = function() {
		$uibModalInstance.dismiss('cancel');

	};
  $scope.logout = function() {
    $uibModalInstance.close();
    $timeout(authServiceLogout, 100);
  };
  function authServiceLogout() {
    AuthService.logout().then(function(authenticated) {
      $state.go('login', {}, {
        reload : true
      });
    }, function(err) {
      $log.info(err);
    });
  }
});

