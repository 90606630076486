angular
  .module("dell-repository.addFolder", [
    "ui.router",
    "taiPlaceholder",
    "ui.bootstrap"
  ])
  .config(function config($stateProvider) {
    $stateProvider.state("addFolder", {
      url: "/addFolder",
      views: {
        login: {
          controller: "AddFolderCtrl",
          templateUrl: "addFolder/addFolder.tpl.html"
        }
      },
      data: {
        pageTitle: "Add Folder"
      }
    });
  })
  .controller("AddFolderCtrl", function AddFolderCtrl(
    $scope,
    $uibModalInstance,
    $timeout,
    AuthService,
    $log,
    focus
  ) {
    var dataSend;
    focus("focusMe");

    //Close modal
    $scope.success = false;
    $scope.cancel = function() {
      $uibModalInstance.dismiss("cancel");
    };

    $scope.inputError = [false];
    $scope.inputSuccess = [false];
    var isProcessing = false;
    $scope.ok = function(data) {
      $scope.inputError = [false];
      $scope.inputSuccess = [false];
      if (data.name && !isProcessing) {
        isProcessing = true;
        AuthService.addFolder(data.name).then(
          function() {
            $uibModalInstance.close("success");
            data.name = "";
          },
          function(err) {
            $uibModalInstance.close("error");
          }
        );
      }
    };
  });
