angular.module('dell-repository.helpers')
.directive('file', function() {
    return {
        restrict: 'A',
        link: function($scope, el, attrs){
            var onChangeHandler = $scope.$eval(attrs.onChange.replace('()', ''));
            el.bind('change', function(event){
                onChangeHandler(event.target.files);
                $scope.$apply();
            });
        }
    };
});