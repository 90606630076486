angular.module('dell-repository.verification', ['ui.router', 'taiPlaceholder', 'ui.bootstrap','dell-repository.confirmation','dell-repository.expired','dell-repository.error']).config(function config($stateProvider) {
	$stateProvider.state('verification', {
		url : '/verification/:token',
		views : {
			"main" : {
				controller : 'VerificationCtrl'
			}
		},
		data : {
			pageTitle : 'DELL Retail Library'
		}
	});
}).controller('VerificationCtrl', function($scope, $state,$stateParams,AuthService) {

	AuthService.login($stateParams.token).then(function(data) {
		$state.go('confirmation');
	}, function(err) {
		//$log.info(err);
		$state.go('error');
	});


});
