angular.module('dell-repository.addToFolder', ['ui.router', 'taiPlaceholder', 'ui.bootstrap', 'dell-repository.helpers']).config(function config($stateProvider) {
	$stateProvider.state('addToFolder', {
		url : '/addToFolder',
		views : {
			"login" : {
				controller : 'AddToFolderCtrl',
				templateUrl : 'addToFolder/addToFolder.tpl.html'
			}
		},
		data : {
			pageTitle : 'Add to Folder'
		}
	});
}).controller('AddToFolderCtrl', function AddToFolderCtrl($scope, $uibModalInstance, $timeout, AuthService, $log, idToGet,$uibModal, NotificationHelper) {
	$scope.isVideo = false;
    $scope.viewFolders = false;
	function loadFolders() {
		AuthService.getFolders().then(function(data) {
			$scope.isMultiple = true;
			$scope.folders = data;
		}, function(err) {
			$log.info(err);
		});
		}

	loadFolders();


	AuthService.getContentDetail(idToGet).then(function(data) {
		var exts = ['jpg', 'jpeg', 'png', 'gif'],
				ext  = data.downloadUrl.split('.');
		var firstAsset = data.assets[0];
		$scope.isMultiple = data.assets.length > 1 ? true : false;
		$scope.videoSrc = undefined;
		$scope.isVideo = false;
		$scope.data = data;
		$scope.defaultThumb = false;
		$scope.videoSrcSeconOption = '';
		$scope.assetImage = 'assets/images/fileTypes/default_' + data.contentType + '.png';
		$scope.assetIcon = 'assets/images/fileTypes/' + data.contentType + '_icon_big.png';
		$scope.assetIconColor = 'assets/images/fileTypes/' + data.contentType + '_icon_color.png';

		if(isVideo(firstAsset.name))
		{
			var filename = firstAsset.downloadUrl.substring(firstAsset.downloadUrl.lastIndexOf('/')+1, firstAsset.downloadUrl.lastIndexOf('.'));
			$scope.videoSrcSeconOption = firstAsset.downloadUrl.substring(0, firstAsset.downloadUrl.lastIndexOf('/')) + '/mp4-' + filename + '.mp4';
			$scope.videoSrc = firstAsset.downloadUrl;
			$scope.isVideo = true;
		}

		if (!!data.thumbnail) {
			$scope.assetImage = data.thumbnail;
		} else if (exts.indexOf(ext[ext.length - 1]) !== -1) {
				$scope.assetImage = data.downloadUrl;
		} else {
			$scope.defaultThumb = true;
		}

	}, function(err) {
		$log.info(err);
	});

	function getExtension(filename) {
		var parts = filename.split('.');
		return parts[parts.length - 1];
	}

	function isImage(filename) {
		var ext = getExtension(filename);
		switch (ext.toLowerCase()) {
		case 'jpg':
		case 'gif':
		case 'bmp':
		case 'png':
			//etc
			return true;
		}
		return false;
	}

	function isVideo(filename) {
		var ext = getExtension(filename);
		switch (ext.toLowerCase()) {
		case 'webm':
		case 'mp4':
		case 'ogv':
		case 'avi':

			// etc
			return true;
		}
		return false;
	}

	function isPdf(filename) {
		var ext = getExtension(filename);
		switch (ext.toLowerCase()) {
		case 'pdf':
			// etc
			return true;
		}
		return false;
	}

	$scope.cancel = function() {
		$uibModalInstance.dismiss('cancel');
	};


	$scope.selectItem = function(asset) {

		if(isVideo(asset.name))
		{
			$scope.isVideo = true;
			var filename = asset.downloadUrl.substring(asset.downloadUrl.lastIndexOf('/')+1, asset.downloadUrl.lastIndexOf('.'));
			$scope.videoSrcSeconOption = asset.downloadUrl.substring(0, asset.downloadUrl.lastIndexOf('/')) + '/mp4-' + filename + '.mp4';
			$scope.videoSrc = asset.downloadUrl;
			var video = document.getElementById('videoRe');
			if(video)
			{
				video.load();
				video.play();
			}
		}
		else if(isPdf(asset.name)){
			var pdffilename = asset.downloadUrl.substring(asset.downloadUrl.lastIndexOf('/')+1, asset.downloadUrl.length);
			var downloadUrl = asset.downloadUrl.substring(0, asset.downloadUrl.lastIndexOf('/')) + '/showPdfVersion' + pdffilename;
			window.open(downloadUrl, '_blank');
		}
		else
		{
			$scope.isVideo = false;
			$scope.assetImage = asset.downloadUrl;
		}
	};

	$scope.openFolders = function (asset) {
		$scope.viewFolders = true;
		$scope.asset = asset;
	};

	$scope.getToggleOptions = function (asset) {
		var options = {};
		$scope.viewFolders = false;
		angular.forEach($scope.data.assets, function (value, key) {
			if (asset.id !== value.id && (value.isActive === true || (value.isBlocked && value.isActive === false))) {
				options.check = true;
			}
		});

		if (!options.check) {
			options.id = $scope.data.id;
		}

		return options;
	};
});

