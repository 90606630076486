angular.module('dell-repository.email', ['ui.router', 'taiPlaceholder', 'ui.bootstrap', 'dell-repository.addContent', 'dell-repository.filterTag', 'dell-repository.helpers']).config(function config($stateProvider) {
	$stateProvider.state('email', {
		url : '/email',
		views : {
			"login" : {
				controller : 'EmailCtrl',
				templateUrl : 'email/email.tpl.html'
			}
		},
		data : {
			pageTitle : 'Send Email'
		}
	});
}).controller('EmailCtrl', function EmailCtrl($scope, $uibModalInstance, $timeout, AuthService, $log, $window) {

	$scope.data = { // holds data that can be retrieved via simple ng-model
		body: '',
		subject: ''
	};

	$scope.error = {}; // controls for validation of each field

	// Cancel action for the model
	$scope.cancel = function () {
		$uibModalInstance.dismiss('cancel');
	};

	var validate = function () {
		var error = false;

		$scope.error.body = !$scope.data.body ? true : false;
		$scope.error.subject = !$scope.data.subject ? true : false;

		$.each($scope.error, function (index, val) {
			error = val ? true : error;
		});

		return !error;
	};

    var isProcessing = false;
	$scope.sendEmail = function(data) {
		if (validate() && !isProcessing) {
				isProcessing = true;
				AuthService.sendEmail(data.subject, data.body).then(function() {
					$uibModalInstance.close('success');
				}, function(err) {
					$uibModalInstance.close('error');
				});
		}
	};
});
