angular.module('dell-repository').service('AuthService', function ($q, $http, USER_ROLES, QueryStringBuilder) {
    var LOCAL_USER_ID = '';
    var username = '';
    var isAuthenticated = false;
    var role = '';
    var userId;
    //var serverURL = 'http://localhost:8080/api/';
    //var serverURL = 'https://dev-services.dellretaillibrary.com//api/';
    // var serverURL = 'https://qa-services.dellretaillibrary.com/api/';
    //var serverURL = 'https://preprod-services.dellretaillibrary.com/api/';
    var serverURL = 'https://services.dellretaillibrary.com/api/';
    //DEV
    //	userId = "a1c73589-51a1-4ace-b98d-ad46c7c97c6f";
    //QA
    //	userId = "b3d22df0-9ba7-4cbb-bfdd-68171f544433";
    //STG
    //	userId = "5b4234f2-6825-4e4a-b879-c916f5feb32c";
    function loadUserCredentials() {
        var datRole = window.localStorage.getItem('LOCAL_USER_ROLE');
        var dataUserId = window.localStorage.getItem('LOCAL_USER_ID');
        var dataAuthToken = window.localStorage.getItem('LOCAL_AUTH_TOKEN');
        if (dataUserId) {
            useCredentials(dataUserId, datRole, dataAuthToken);
        }
    }

    function storeUserCredentials(dataUserId, dataUserRole, dataAuthToken) {
        window.localStorage.setItem('LOCAL_USER_ID', dataUserId);
        window.localStorage.setItem('LOCAL_USER_ROLE', dataUserRole);
        window.localStorage.setItem('LOCAL_AUTH_TOKEN', dataAuthToken);
        useCredentials(dataUserId, dataUserRole, dataAuthToken);
    }

    function useCredentials(dataUserId, dataUserRole, dataAuthToken) {
        userId = dataUserId;
        userRole = dataUserRole;
        isAuthenticated = true;
        switch (userRole) {
            case 'admin':
                role = USER_ROLES.admin;
                break;
            case 'publisher':
                role = USER_ROLES.publisher;
                break;
            case 'marketer':
                role = USER_ROLES.marketer;
                break;
        }
        // Set the token as header for your requests!
        $http.defaults.headers.common['Authorization'] = 'Basic ' + dataAuthToken;
        //		$http.defaults.headers.common['authentication_token'] = dataAuthToken;
    }

    function destroyUserCredentials() {
        username = '';
        isAuthenticated = false;
        //		$http.defaults.headers.common['authentication_token'] = undefined;
        window.localStorage.removeItem('LOCAL_USER_ID');
        role = '';
        userId = '';
    }

    var login = function (token) {
        return $q(function (resolve, reject) {
            var url = serverURL + 'login';
            data = {
                "token": token
            };
            $http.post(url, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(function ({ data }) {
                if (data.code == 200) {
                    resolve('Log in success.');
                    //Save locally user credentials
                    storeUserCredentials(data.data.id, data.data.role, token);
                } else {
                    reject('Login Failed, Check your credentials.');
                }
            }, function (data) {
                reject('Login Failed.');
            });
        });
    };

    var loginMail = function (user) {
        return $q(function (resolve, reject) {
            var url = serverURL + 'auth/email';
            data = {
                "email": user
            };
            $http.post(url, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(function ( { data }) {
                if (data.code == 200) {
                    resolve('Log in success.');
                    //Save locally user credentials
                    //storeUserCredentials(data.data.id, data.data.role);
                } else {
                    reject('Login Failed, Check your credentials.');
                }
            },function (data) {
                reject('Login Failed.');
            });
        });
    };
    var createUser = function (data) {
        return $q(function (resolve, reject) {
            var url = serverURL + 'user';
            $http.post(url, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(function ({ data }) {
                if (data.code == 201) {
                    resolve('Sign up success.');
                }
            }, function ( { data }) {
                if (data.code == 400) {
                    reject("Bad request");
                }
                if (data.code == 401) {
                    reject("User not authenticated");
                }

            });
        });
    };
    var createUserOneByOne = function (emailToAdd, roleToAdd) {
        return $q(function (resolve, reject) {
            var url = serverURL + 'user';
            $http.post(url, {
                "users": [{
                    "role": roleToAdd,
                    "email": emailToAdd
                }]
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(function ({ data }) {
                if (data.code == 201) {
                    resolve('Sign up success.');
                }
            }, function ({ data }) {
                if (data.code == 400) {
                    reject("Bad request");
                }
                if (data.code == 500) {
                    reject("Error");
                }

            });
        });
    };
    var updateUser = function (data) {
        return $q(function (resolve, reject) {
            var url = serverURL + 'user/' + data.id;
            $http.put(url, {
                "user": {
                    "role": data.role,
                    "email": data.email
                }
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(function ({ data }) {
                if (data.code == 200) {
                    resolve('Sign up success.');
                }
            }, function ({ data }) {
                if (data.code == 400) {
                    reject("Bad request");
                }
                if (data.code == 401) {
                    reject("User not authenticated");
                }
            });
        });
    };
    var deleteUser = function (data) {
        return $q(function (resolve, reject) {
            var requestUrl = serverURL + 'user/' + data;
            $http({
                url: requestUrl,
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'DELETE'
            }).then(function () {
                resolve('Delete success.');
            }, function ({ data }) {
                if (data.code == 400) {
                    reject("Bad request");
                }
                if (data.code == 401) {
                    reject("User not authenticated");
                }

            });
        });
    };

    var deleteFolder = function (folderId) {
        return $q(function (resolve, reject) {
            var requestUrl = serverURL + folderId + '/collection/folder';
            $http({
                url: requestUrl,
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'DELETE'
            }).then(function () {
                resolve('Delete success.');
            }, function ({ data }) {
                if (data.code == 400) {
                    reject("Bad request");
                }
                if (data.code == 401) {
                    reject("User not authenticated");
                }
            });
        });
    };

    var editContent = function (data) {
        var fd = new FormData();

        $.each(data.content, function (index) {
            if (!!data.content[index].id) {
                // here tackle the existing items
                fd.append('content_asset_ids', angular.toJson({
                    id: data.content[index].id,
                    name: data.content[index].name
                }));
            } else {
                // handle the new files
                fd.append('content', data.content[index]);
            }
        });

        $.each(data.contentDetails, function (index) {
            fd.append('content_details', angular.toJson(data.contentDetails[index]));
        });

        fd.append('content_id', data.content_id);
        fd.append('thumbnail', data.thumbnail);
        fd.append('use_old_thumbnail', (data.useOldThumbnail ? "1" : "0"));
        fd.append('description', data.description);
        fd.append('tags', data.tags);
        fd.append('deleted_files', data.deletedFiles);
        fd.append('type', data.type);

        if (data.name !== "") {
            fd.append('name', data.name);
        }

        if (data.license !== "") {
            fd.append('license', data.license);
        }

        if (!data.hasNoExpiration) {
            fd.append('licenseExpiration', data.licenseExpiration);
        }

        return $q(function (resolve, reject) {
            var requestUrl = serverURL + 'content';
            $http.put(requestUrl, fd, {
                transformRequest: angular.identity,
                headers: {
                    'Content-Type': undefined
                }
            }).then(function () {
                resolve('Delete success.');
            }, function ({ data }) {
                if (data.code == 400) {
                    reject("Bad request");
                }
                if (data.code == 401) {
                    reject("User not authenticated");
                }
            });
        });
    };

    var addContent = function (data) {
        var fd = new FormData();

        $.each(data.content, function (index) {
            fd.append('content', data.content[index]);
        });

        $.each(data.contentDetails, function (index) {
            fd.append('contentDetails', angular.toJson(data.contentDetails[index]));
        });

        fd.append('thumbnail', data.thumbnail);
        fd.append('description', data.description);
        fd.append('tags', data.tags);
        fd.append('type', data.type);

        if (data.name !== "") {
            fd.append('name', data.name);
        }

        if (data.license !== "") {
            fd.append('license', data.license);
        }

        if (!data.hasNoExpiration) {
            fd.append('licenseExpiration', data.licenseExpiration);
        }

        return $q(function (resolve, reject) {
            var requestUrl = serverURL + 'content';
            $http.post(requestUrl, fd, {
                transformRequest: angular.identity,
                headers: {
                    'Content-Type': undefined
                }
            }).then(function () {
                resolve('Delete success.');
            }, function ({ data }) {
                if (data.code == 400) {
                    reject("Bad request");
                }
                if (data.code == 401) {
                    reject("User not authenticated");
                }

            });
        });
    };

    var shareFolder = function (email, folderId) {
        var fd = new FormData();

        fd.append('email', email);
        fd.append('currentUserID', userId);

        return $q(function (resolve, reject) {
            var requestUrl = serverURL + folderId + '/collection/folderShare';
            $http.post(requestUrl, fd, {
                transformRequest: angular.identity,
                headers: {
                    'Content-Type': undefined
                }
            }).then(function () {
                resolve('Create success.');
            }, function ({ data }) {
                if (data.code == 400) {
                    reject("Bad request");
                }
                if (data.code == 401) {
                    reject("User not authenticated");
                }
                if (data.code == 404) {
                    reject("User email not found");
                }
            });
        });
    };


    var addFolder = function (name) {
        var fd = new FormData();

        fd.append('name', name);

        return $q(function (resolve, reject) {
            var requestUrl = serverURL + userId + '/collection/folder';
            $http.post(requestUrl, fd, {
                transformRequest: angular.identity,
                headers: {
                    'Content-Type': undefined
                }
            }).then(function () {
                resolve('Create success.');
            }, function ({ data }) {
                if (data.code == 400) {
                    reject("Bad request");
                }
                if (data.code == 401) {
                    reject("User not authenticated");
                }
            });
        });
    };

    var sendEmail = function (subject, body) {
        var fd = new FormData();

        fd.append('subject', subject);
        fd.append('body', body);

        return $q(function (resolve, reject) {
            var requestUrl = serverURL + userId + '/email';
            $http.post(requestUrl, fd, {
                transformRequest: angular.identity,
                headers: {
                    'Content-Type': undefined
                }
            }).then(function () {
                resolve('Create success.');
            }, function ({ data }) {
                if (data.code == 400) {
                    reject("Bad request");
                }
                if (data.code == 401) {
                    reject("User not authenticated");
                }
            });
        });
    };


    var addMetric = function (content_id, type) {
        var fd = new FormData();

        fd.append('content_id', content_id);
        fd.append('type', type);

        return $q(function (resolve, reject) {
            var requestUrl = serverURL + 'metric';
            $http.post(requestUrl, fd, {
                transformRequest: angular.identity,
                headers: {
                    'Content-Type': undefined
                }
            }).then(function () {
                resolve('Create success.');
            }, function ({ data }) {
                if (data.code == 400) {
                    reject("Bad request");
                }
                if (data.code == 401) {
                    reject("User not authenticated");
                }
            });
        });
    };

    var editFolder = function (folderId, name) {
        var fd = new FormData();

        fd.append('name', name);

        return $q(function (resolve, reject) {
            var requestUrl = serverURL + folderId + '/collection/folder';
            $http.put(requestUrl, fd, {
                transformRequest: angular.identity,
                headers: {
                    'Content-Type': undefined
                }
            }).then(function () {
                resolve('Create success.');
            }, function ({ data }) {
                if (data.code == 400) {
                    reject("Bad request");
                }
                if (data.code == 401) {
                    reject("User not authenticated");
                }
            });
        });
    };

    var deleteContent = function (data) {
        return $q(function (resolve, reject) {
            var requestUrl = serverURL + 'content/' + data;
            $http({
                url: requestUrl,
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'DELETE'
            }).then(function () {
                resolve('Delete success.');
            }, function ({ data }) {
                if (data.code == 400) {
                    reject("Bad request");
                }
                if (data.code == 401) {
                    reject("Content not authenticated");
                }

            });
        });
    };

    var logout = function () {
        return $q(function (resolve, reject) {
            var url = serverURL + 'logout';
            destroyUserCredentials();
            resolve('Log out success.');
        });
    };
    var isAuthorized = function (authorizedRoles) {
        if (!angular.isArray(authorizedRoles)) {
            authorizedRoles = [authorizedRoles];
        }
        return (isAuthenticated && authorizedRoles.indexOf(role) !== -1);
    };

    //Get users

    var getUsers = function (page, amountOfUsers) {
        return $q(function (resolve, reject) {
            var url = serverURL + 'user?' + "page=" + page + "&count=" + amountOfUsers;
            $http.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'page': page
                }
            }).then(function ({ data }) {
                if (data.code == 200) {
                    resolve(data.data);
                } else {
                    reject('Invalid userID' + data.code);
                }
            }, function ({ data }) {
                if (data.code == 400) {
                    reject("Bad request");
                }
                if (data.code == 401) {
                    reject("User not authenticated");
                }
            });
        });
    };

    //Get content

    var getContent = function (page, count, filters, filterArray, search, sorting, sortDirection) {
        return $q(function (resolve, reject) {
            var url = "";
            var arrayToSend = [];
            var cont = 0;
            angular.forEach(filterArray, function (value, key) {
                if (value.length > 0) {

                    arrayToSend.push(value);
                }
            });
            url = serverURL + 'content/filter';
            $http.post(url, {
                "count": count,
                "page": page,
                "filters": filterArray,
                "criteria": search,
                "sort": sorting,
                'sort_direction': sortDirection
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(function ({ data }) {
                if (data.code == 200) {
                    resolve(data.data);
                } else {
                    reject('Error ' + data.code);
                }
            }, function ({ data }) {
                if (!data.code) {
                    reject("Bad request");
                }
            });
        });
    };

    //Get content

    var getContentDetail = function (contentId) {
        return $q(function (resolve, reject) {
            var url = serverURL + 'content/' + contentId;
            $http.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(function ({ data }) {
                if (data.code == 200) {
                    resolve(data.data.content);
                } else {
                    reject('Error ' + data.code);
                }
            }, function ({ data }) {
                if (!data.code) {
                    reject("Bad request");
                }
            });
        });
    };

    var downloadSelectedAssets = function (contentId, assetIds) {
        return $q(function (resolve, reject) {
            var i;
            var payload = '';
            for(i = 0; i < assetIds.length; i++){
                payload += "file_id=" + assetIds[i].id + '&';
            }
            var url = serverURL + 'content/d/'+ contentId+ '/zip?' + payload;
            $http.get(url).then(function ({ data }) {
                resolve(data.content);
            }, function () {
                reject("Bad request");
            });
        });
    };


    /**
     * Gets filtered images according to filters array
     *
     * @param {*} contentId Content id
     * @param {*} filters Array of filters
     * @returns Promise with possible results.
     */
    var getFilteredImages = function (contentId, filters) {
        var queryString = QueryStringBuilder.build(filters);

        return $q(function (resolve, reject) {
            var endpoint = 'content/' + contentId;
            var url = serverURL;

            if (queryString.length > 0) {
                endpoint += '?' + queryString;
            }

            url += endpoint;

            $http.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(function ({ data }) {
                if (data.code == 200) {
                    resolve(data.data.content);
                } else {
                    reject('Error ' + data.code);
                }
            }, function ({ data }) {
                if (!data.code) {
                    reject("Bad request");
                }
            });
        });
    };

    // Get Autocomplete
    var getAutocompleteInformation = function (tagName, textToFind, asset_id) {
        return $q(function (resolve, reject) {
            var url = serverURL + 'tag/' + tagName + '/suggestions?has_text=' + textToFind;
            if(!!asset_id){
                url += ('&id=' + asset_id);
            }
            $http.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(function ({ data }) {
                if (data.code == 200) {
                    resolve(data.data.suggestions);
                } else {
                    reject('Error ' + data.code);
                }
            }, function ({ data }) {
                if (!data.code) {
                    reject("Bad request");
                }
            });
        });
    };

    // Add collection

    var addToCollection = function (folder_id, idToAdd) {
        return $q(function (resolve, reject) {
            var url = serverURL + folder_id + '/collection';

            $http.post(url, {
                "contentId": idToAdd
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(function ({ data }) {
                if (data.code == 200) {
                    resolve(data.data);
                }
            }, function ({ data }) {
                if (data.code == 400) {
                    reject("Bad request");
                }
                if (data.code == 401) {
                    reject("User not authenticated");
                }

            });
        });
    };

    //Get collection

    var getCollection = function (folder_id) {
        return $q(function (resolve, reject) {
            var url = serverURL + folder_id + '/collection';
            $http.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(function ({ data }) {
                if (data.code == 200) {
                    resolve(data.data);
                } else {
                    reject('Error ' + data.code);
                }
            }, function ({ data }) {
                if (data.code == 400) {
                    reject("Bad request");
                }
            });
        });
    };

    //Delete Collection

    var deleteCollection = function (folder_id, data) {
        return $q(function (resolve, reject) {
            var url = serverURL + folder_id + '/collection';
            $http({
                url: url,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    "contentId": data
                },
                method: 'DELETE'
            }).then(function () {
                resolve('Delete success.');
            }, function ({ data }) {
                if (data.code == 400) {
                    reject("Bad request");
                }
                if (data.code == 401) {
                    reject("Content not authenticated");
                }

            });
        });
    };

    //Export collection

    var exportCollection = function (folder_id) {
        return serverURL + folder_id + '/collection/export';
    };


    //Export collection

    var importCollection = function (folder_id, content) {
        var fd = new FormData();
        fd.append('content', content);
        return $q(function (resolve, reject) {
            var requestUrl = serverURL + folder_id + '/collection/import';
            $http.post(requestUrl, fd, {
                transformRequest: angular.identity,
                headers: {
                    'Content-Type': undefined
                }
            }).then(function () {
                resolve('Import success.');
            }, function ({ data }) {
                if (data.code == 400) {
                    reject("Bad request");
                }
                if (data.code == 401) {
                    reject("User not authenticated");
                }

            });
        });
    };

    var getFolders = function () {
        return $q(function (resolve, reject) {
            var url = serverURL + userId + '/collection/folder';
            $http.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {'foobar': new Date().getTime()}
            }).then(function ({ data }) {
                if (data.code == 200) {
                    resolve(data.data);
                } else {
                    reject('Error ' + data.code);
                }
            }, function ({ data }) {
                if (data.code == 400) {
                    reject("Bad request");
                }
            });
        });
    };

    //Get filters

    var getFilterTags = function (type) {
        return $q(function (resolve, reject) {
            var url = serverURL + 'tag?' + "cat=" + type;
            $http.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(function ({ data }) {
                if (data.code == 200) {
                    resolve(data.data);

                } else {
                    reject('Error getting filter tags' + data.code);
                }
            }, function ({ data }) {
                if (data.code == 400) {
                    reject("Bad request");
                }
            });
        });
    };


    var readAsDataUrl = function (file, scope) {
        var deferred = $q.defer();

        var reader = getReader(deferred, scope);
        reader.readAsDataURL(file);

        return deferred.promise;
    };
    loadUserCredentials();
    var onLoad = function (reader, deferred, scope) {
        return function () {
            scope.$apply(function () {
                deferred.resolve(reader.result);
            });
        };
    };

    var onError = function (reader, deferred, scope) {
        return function () {
            scope.$apply(function () {
                deferred.reject(reader.result);
            });
        };
    };

    var onProgress = function (reader, scope) {
        return function (event) {
            scope.$broadcast("fileProgress", {
                total: event.total,
                loaded: event.loaded
            });
        };
    };

    var getReader = function (deferred, scope) {
        var reader = new FileReader();
        reader.onload = onLoad(reader, deferred, scope);
        reader.onerror = onError(reader, deferred, scope);
        reader.onprogress = onProgress(reader, scope);
        return reader;
    };

    return {
        login: login,
        loginMail: loginMail,
        logout: logout,
        getUsers: getUsers,
        createUser: createUser,
        createUserOneByOne: createUserOneByOne,
        updateUser: updateUser,
        deleteUser: deleteUser,
        getContentDetail: getContentDetail,
        getAutocompleteInformation: getAutocompleteInformation,
        getContent: getContent,
        downloadSelectedAssets: downloadSelectedAssets,
        addContent: addContent,
        editContent: editContent,
        deleteContent: deleteContent,
        getFilteredImages: getFilteredImages,
        getFolders: getFolders,
        addFolder: addFolder,
        deleteFolder: deleteFolder,
        editFolder: editFolder,
        addToCollection: addToCollection,
        getCollection: getCollection,
        deleteCollection: deleteCollection,
        exportCollection: exportCollection,
        importCollection: importCollection,
        getFilterTags: getFilterTags,
        isAuthorized: isAuthorized,
        shareFolder: shareFolder,
        addMetric: addMetric,
        readAsDataUrl: readAsDataUrl,
        sendEmail: sendEmail,
        isAuthenticated: function () {
            return isAuthenticated;
        },
        username: function () {
            return username;
        },
        role: function () {
            return role;
        }
    };
})
//Service to verify if the browser is supported.
    .service('BrowserInfo', function () {

        var supportedBrowsers = [{
            name: 'chrome',
            version: 47
        }, {
            name: 'firefox',
            version: 42
        }, {
            name: 'ie',
            version: 11
        }, {
            name: 'safari',
            version: 8
        },
            {
                name: 'edge',
                version: 1
            }];

        function get_browser_info() {
            var ua = navigator.userAgent, tem,
                M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
            if (/trident/i.test(M[1])) {
                tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
                return {
                    name: 'ie',
                    version: (tem[1] || '')
                };
            }
            if (M[1] === 'Chrome') {
                tem = ua.match(/\b(Edge)\/(\d+)/);
                if (tem != null) {
                    return {
                        name: 'edge',
                        version: tem.slice(1)[1]
                    };
                }
            }
            M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
            if ((tem = ua.match(/version\/(\d+)/i)) != null) {
                M.splice(1, 1, tem[1]);
            }
            return {
                name: M[0],
                version: M[1]
            };
        }

        return {
            isSupported: function isSupportedBrowser() {
                var browserInfo = get_browser_info();
                for (var i = 0; i < supportedBrowsers.length; i++) {
                    var supportedBrowser = supportedBrowsers[i];
                    if (supportedBrowser.name.toLowerCase() === browserInfo.name.toLowerCase()) {
                        if (browserInfo.version >= supportedBrowser.version) {
                            return true;
                        }
                    }
                }
                return false;
            }
        };
    })
    //Service to verify if search conditions are full
    .service('searchable', function () {
        return {
            searchNow: function (searchType, selectedLanguage) {
                if (selectedLanguage == null) {
                    return false;
                }
                if (searchType !== 0 && selectedLanguage !== "") {
                    return true;
                }
                return false;
            }
        };
    }).factory('AuthInterceptor', function ($rootScope, $q, AUTH_EVENTS) {
    return {
        responseError: function (response) {
            $rootScope.$broadcast({
                401: AUTH_EVENTS.notAuthenticated,
                403: AUTH_EVENTS.notAuthorized
            }[response.status], response);
            return $q.reject(response);
        }
    };
}).config(function ($httpProvider) {
    $httpProvider.interceptors.push('AuthInterceptor');
});
