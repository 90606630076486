/*** IMPORTS FROM imports-loader ***/
var angular = require("angular");

var angular=window.angular,ngModule;
try {ngModule=angular.module(["ng"])}
catch(e){ngModule=angular.module("ng",[])}
var v1="<ul class=\"accordionDetail level{{level}}\">\n<li ng-repeat=\"tag in tags\" ng-if=\"tag.hidden !== true\">\n<input type=\"checkbox\" class=\"filterCheckBox\" id=\"filter-{{tag.id}}\" name=\"{{tag.name}}\" ng-checked=\"doCheck('filter-{{tag.id}}', 'level{{level + 1}}', tag)\" data-toggle=\"level{{level + 1}}\"/>\n<label class=\"filterCheckBox\" for=\"filter-{{tag.id}}\">\n<span class=\"filterCheckBox\"></span>\n{{tag.displayName}}\n</label>\n<filter-tag ng-if=\"tag.children.length > 0\" selected=\"selected\" tags=\"tag.children\" level=\"level + 1\" set=\"set()\"></filter-tag>\n</li>\n</ul>";
var id1="library/filterTag/filterTag.tpl.html";
var inj=angular.element(window.document).injector();
if(inj){inj.get("$templateCache").put(id1,v1);}
else{ngModule.run(["$templateCache",function(c){c.put(id1,v1)}]);}
module.exports=v1;
