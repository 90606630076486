angular.module('dell-repository.helpers')
  .directive('dotdotdot', function($timeout){
    return {
      restrict: 'A',
      link: function(scope, element, attributes) {
        $timeout(function() {
          element.dotdotdot({wrap: 'letter'});
        });
      }
    };
  });
