angular.module('dell-repository.helpers')
.factory('NotificationHelper', function(){
  return {
    notify: function(text, status, timeout){
      var container = $('#notifications'),
          element = $('<div/>', {class: 'alert ' + status}),
          fadeDuration = 300;

      element.text(text);
      element.appendTo(container);
      element.fadeIn(fadeDuration);

      if (!!timeout) {
        setTimeout(function () {
          container.animate({marginTop: -element.height() - 20}, fadeDuration, "linear");
          element.fadeOut(fadeDuration, function () {
            element.remove();
            container.css('margin-top', 0);
          });
        }, timeout);
      }

    }
  };
});