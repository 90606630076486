angular.module('dell-repository.deleteUser', ['ui.router', 'taiPlaceholder', 'ui.bootstrap']).config(function config($stateProvider) {
	$stateProvider.state('deleteUser', {
		url : '/deleteUser',
		views : {
			"login" : {
				controller : 'DeleteUserCtrl',
				templateUrl : 'deleteUser/deleteUser.tpl.html'
			}
		},
		data : {
			pageTitle : 'Forgot Password'
		}
	});
}).controller('DeleteUserCtrl', function DeleteUserCtrl($scope, $uibModalInstance, $timeout, AuthService, $log,userToDelete) {
	var dataSend;

	$timeout(function() {
		$scope.focusInput = true;
	}, 700);
	$scope.ok2 = function() {
		$scope.focusInput = false;
		AuthService.deleteUser(userToDelete).then(function(authenticated) {
			$log.info('succesfull');
			//			$scope.success = true;
			$uibModalInstance.close();
		}, function(err) {
			//			$scope.success = false;
			$log.info(err);
		});
	};
	$scope.cancel = function() {
		$uibModalInstance.dismiss('cancel');
	};

});

