angular
  .module("dell-repository.getContent", [
    "ui.router",
    "taiPlaceholder",
    "ui.bootstrap",
    "dell-repository.helpers"
  ])
  .config(function config($stateProvider) {
    $stateProvider.state("getContent", {
      url: "/getContent",
      views: {
        login: {
          controller: "GetContentCtrl",
          templateUrl: "getContent/getContent.tpl.html"
        }
      },
      data: {
        pageTitle: "Forgot Password"
      }
    });
  })
  .controller("GetContentCtrl", function GetContentCtrl(
    $scope,
    $uibModalInstance,
    $timeout,
    AuthService,
    $log,
    idToGet,
    filters,
    $uibModal,
    NotificationHelper,
    $location,
    $window,
    SCREENFILL,
    EXTENSION_TO_ICON,
    QueryStringBuilder,
    SharedFunctionsService
  ) {
    $scope.isVideo = false;
    $scope.screenfillList = SCREENFILL[0].children;
    $scope.details = filters.details;
    $scope.color = filters.color;
    $scope.colorsAvailable = [];
    $scope.screenfill = SCREENFILL[0].children.filter(function (screenfill) {  return screenfill.id === filters.screenfill; })[0];
    $scope.filters = QueryStringBuilder.parse(filters).filter(function (filter) {
      var keys = Object.keys(filter);
      return keys[0] !== 'contentId';
    });
    $scope.isFilterPreloaded = !!$scope.filters && $scope.filters.length > 0;
    $scope.multimediaHeaders = [];
    $scope.downloadingZip = false;

    $scope.viewFolders = false;
    function loadFolders() {
      AuthService.getFolders().then(
        function (data) {
          $scope.isMultiple = true;
          $scope.folders = data;
        },
        function (err) {
          $log.info(err);
        }
      );
    }

    loadFolders();

    $scope.isSelectForDownloadDisable = function (asset) {
      if (!asset.checked && $scope.selectedForDownload().length >= 20) {
        return true;
      }
      return false;
    };

    $scope.downloadSelected = function () {
      var dowloadable = $scope.selectedForDownload();
      if (dowloadable.length > 0) {
        $scope.downloadingZip = true;
        AuthService.downloadSelectedAssets($scope.data.id, dowloadable).then(
            function (data) {
              $scope.downloadingZip = false;
              $window.open(data.download_url, "_blank");
              // Clear all the selected items
              var len = $scope.data.assets.length;
              for(var i = 0; i < len; i++){
                $scope.data.assets[i].checked = false;
              }
            },
            function (err) {
              $log.info(err);
            });
      }
    };

    $scope.selectedForDownload = function(){
      if($scope.data){
        return $scope.data.assets.filter(function (asset) {
          return asset.checked;
        });
      }
    };

    $scope.showAddCollectionForm = function () {
      var modalInstance = $uibModal.open({
        templateUrl: "folder/addFolder/addFolder.tpl.html",
        controller: "AddFolderCtrl",
        scope: $scope,
        windowClass: "app-modal-window-addFolder",
        backdropClass: "app-modal-BackDropWindow"
      });

      setTimeout(function () {
        $("#addFolderModalButton").click(function () {
          loadFolders();
        });
      }, 1000);
    };

    $scope.fileIcon = function(asset){
      var fileExtension = asset.name.split('.').pop();
      if(EXTENSION_TO_ICON[fileExtension] !== undefined){
        return "assets/images/fileTypes/" + EXTENSION_TO_ICON[fileExtension] + "_icon.png";
      } else {
        return $scope.assetIconColor;
      }
    };

    AuthService.getContentDetail(idToGet).then(
      function (data) {
        var exts = ["jpg", "jpeg", "png", "gif"],
          ext = data.downloadUrl.split(".");
        var firstAsset = data.assets[0];

        AuthService.addMetric(firstAsset.id, 2).then(
          function () { },
          function (err) { }
        );
        $scope.isMultiple = data.assets.length > 1 ? true : false;
        $scope.videoSrc = undefined;
        $scope.isVideo = false;
        $scope.data = data;
        $scope.colorDropdownInformation(idToGet);
        $scope.defaultThumb = false;
        $scope.videoSrcSeconOption = "";
        $scope.assetImage =
          "assets/images/fileTypes/default_" + data.contentType + ".png";
        $scope.assetIcon =
          "assets/images/fileTypes/" + data.contentType + "_icon_big.png";
        $scope.assetIconColor =
          "assets/images/fileTypes/" + data.contentType + "_icon.png";

        $scope.documentFiles = getPinnedDocuments(data.assets);
        initMultimediaAssets(data.assets);

        if (isVideo(firstAsset.name)) {
          var filename = firstAsset.downloadUrl.substring(
            firstAsset.downloadUrl.lastIndexOf("/") + 1,
            firstAsset.downloadUrl.lastIndexOf(".")
          );
          $scope.videoSrcSeconOption =
            firstAsset.downloadUrl.substring(
              0,
              firstAsset.downloadUrl.lastIndexOf("/")
            ) +
            "/mp4-" +
            filename +
            ".mp4";
          $scope.videoSrc = firstAsset.downloadUrl;
          $scope.isVideo = true;
        }
        if (!!data.thumbnail) {
          $scope.assetImage = data.thumbnail;
        } else if (exts.indexOf(ext[ext.length - 1]) !== -1) {
          $scope.assetImage = data.downloadUrl;
        } else {
          $scope.defaultThumb = true;
        }
        $("#getContentWrap").fadeIn();
      },
      function (err) {
        $log.info(err);
        $("#getContentWrap").fadeIn();
      }
    );

    function getFileName(file) {
      return file.substring(0, file.lastIndexOf("."));
    }

    function getPinnedDocuments(assets) {
      // Missing the pinned docs filtering.
      return assets.filter(function (asset) {
        return (asset.pinned || !isImage(asset.name) && !isVideo(asset.name));
      });
    }

    function groupFilters(type, filters) {
      var formatFilter = function (f) {
        return {
          name: f.name,
          displayName: f.displayName,
          checked: false,
          hidden: f.hidden
        };
      };
      var assetType = filters.filter(function (f) { return f.canFilter; });
      var groups = filters.filter(function (f) { return !f.canFilter; });

      var groupFiltersArr = [];
      groupFiltersArr.push({
        group: type + " Type",
        hidden: false,
        children: assetType.map(formatFilter)
      });
      groups.forEach(function (group) {
        groupFiltersArr.push({
          group: group.name,
          hidden: group.hidden,
          children: group.children.map(formatFilter)
        });
      });
      return groupFiltersArr;
    }

    function getFormattedImages(assets, formattedFilters) {
      return {
        tabTitle: 'Images',
        type: "image",
        assets: !$scope.isFilterPreloaded ? getImages(assets) : [],
        filterGroups: formattedFilters.image,
        allowFilter: true
      };
    }

    function getFormattedVideos(assets, formattedFilters) {
      return {
        tabTitle: 'Videos',
        type: "video",
        assets: getVideos(assets),
        filterGroups: formattedFilters.video,
        allowFilter: false
      };
    }

    function getFormattedFilters(filters) {
      var formatedFilters = {};
      filters.forEach(function (val) {
        formatedFilters[val.displayName.toLowerCase()] = groupFilters(val.displayName, val.children);
      });

      return formatedFilters;
    }

    function formatMultimediaAssets(assets, filters) {
      var formatedFilters = getFormattedFilters(filters);
      return [
        angular.extend({}, getFormattedImages(assets, formatedFilters)),
        angular.extend({}, getFormattedVideos(assets, formatedFilters))
      ];
    }

    function getImages(assets, filters) {
      return assets.filter(function (asset) {
        return !asset.pinned && isImage(asset.name);
      });
    }

    function getVideos(assets, filters) {
      return assets.filter(function (asset) {
        return !asset.pinned && isVideo(asset.name);
      });
    }

    function isImage(filename) {
      var ext = SharedFunctionsService.getExtension(filename);
      switch (ext.toLowerCase()) {
        case "jpg":
        case "jpeg":
        case "gif":
        case "bmp":
        case "png":
        case "psd":
          //etc
          return true;
      }
      return false;
    }

    function initMultimediaAssets(assets) {
      AuthService.getFilterTags('p').then(
        function (data) {
          var assetTag = data.tags.filter(function (tag) {
            return tag.displayName === "Asset Type";
          });
          var filters = assetTag[0].children.filter(function (filter) {
            return filter.displayName === 'Image' || filter.displayName === 'Video';
          });
          $scope.multimedia = formatMultimediaAssets(assets, filters);

          if ($scope.isFilterPreloaded) {
            filterImages();
          }

          $scope.multimediaHeaders = $scope.multimedia.map(function (m) {
            return angular.extend({}, m, { assets: undefined, filterGroups: undefined });
          });
        }
      );
    }

    $scope.openFolders = function (asset) {
      $scope.viewFolders = true;
      $scope.asset = asset;
    };

    $scope.closeFolders = function () {
      $scope.viewFolders = false;
    };

    function isVideo(filename) {
      var ext = SharedFunctionsService.getExtension(filename);
      switch (ext.toLowerCase()) {
        case "webm":
        case "mp4":
        case "ogv":
        case "avi":
        case "wmv":
          // etc
          return true;
      }
      return false;
    }

    function isPdf(filename) {
      var ext = SharedFunctionsService.getExtension(filename);
      switch (ext.toLowerCase()) {
        case "pdf":
          // etc
          return true;
      }
      return false;
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss("cancel");
    };

    $scope.selectItem = function (asset, assets) {
      AuthService.addMetric(asset.id, 2).then(function () { }, function (err) { });

      if (isVideo(asset.name)) {
        $scope.isVideo = true;
        var filename = asset.downloadUrl.substring(
          asset.downloadUrl.lastIndexOf("/") + 1,
          asset.downloadUrl.lastIndexOf(".")
        );
        $scope.videoSrcSeconOption =
          asset.downloadUrl.substring(0, asset.downloadUrl.lastIndexOf("/")) +
          "/mp4-" +
          filename +
          ".mp4";
        $scope.videoSrc = asset.downloadUrl;
        var video = document.getElementById("videoRe");
        if (video) {
          video.load();
          video.play();
        }
      } else if (isPdf(asset.name)) {
        var pdffilename = asset.downloadUrl.substring(
          asset.downloadUrl.lastIndexOf("/") + 1,
          asset.downloadUrl.length
        );
        var downloadUrl =
          asset.downloadUrl.substring(0, asset.downloadUrl.lastIndexOf("/")) +
          "/showPdfVersion" +
          pdffilename;
        window.open(downloadUrl, "_blank");
      } else if (isImage(asset.name)) {
        if (SharedFunctionsService.getExtension(asset.name).toLowerCase() === 'psd') {
          var imageForPsd = SharedFunctionsService.findImageForPsd(assets, SharedFunctionsService.getFileName(asset.name));
          $scope.assetImage =  (imageForPsd && imageForPsd.downloadUrl) || 'assets/images/library/no_preview.png';
        } else {
          $scope.assetImage = asset.downloadUrl;
        }
        $scope.isVideo = false;
      } else {
        $scope.isVideo = false;
        $scope.assetImage = 'assets/images/library/no_preview.png';
      }
    };

    $scope.getToggleOptions = function (asset) {
      var options = {};
      $scope.viewFolders = false;
      angular.forEach($scope.data.assets, function (value, key) {
        if (
          asset.id !== value.id &&
          (value.isActive === true ||
            (value.isBlocked && value.isActive === false))
        ) {
          options.check = true;
        }
      });

      if (!options.check) {
        options.id = $scope.data.id;
      }

      return options;
    };

    $scope.openFilter = function (i) {
      var tabClass = ".tab-" + i;

      // Change current active tab and close filter if they are open.
      if (!$(tabClass).hasClass('active') && $timeout) {
        $timeout(function () {
          $(tabClass + " a[data-toggle=tab]").click();
          $(".panel.filters").removeClass("open");
          $(tabClass + " .filters").addClass("open");
        });
      } else {
        $(tabClass + " .filters").addClass("open");
      }
    };

    $scope.closeFilters = function () {
      $(".filters").removeClass("open");
    };

    $scope.shareAll = function () {
      var copyElement = document.createElement("textarea");
      copyElement.style.position = 'fixed';
      copyElement.style.opacity = '0';

      var absUrl = $location.absUrl();
      var locationUrl = $location.path();
      var siteUrl = absUrl.substring(0, absUrl.indexOf(locationUrl));
      copyElement.textContent = siteUrl + $location.path() + '?contentId=' + idToGet;

      var queryStrings = QueryStringBuilder.build($scope.filters);
      if (queryStrings && queryStrings !== '') {
        copyElement.textContent += '&' + queryStrings;
      }

      var body = document.getElementsByTagName('body')[0];
      body.appendChild(copyElement);
      copyElement.select();
      document.execCommand('copy');
      body.removeChild(copyElement);

      NotificationHelper.notify(
        "Share link has been copied to the clipboard",
        "success",
        2500
      );
    };

    $scope.updateFilter = function (type, group, filter) {
      var currentVal = $scope.multimedia[type].filterGroups[group].children[filter].checked;
      $scope.multimedia[type].filterGroups[group].children[filter].checked = !currentVal;
    };

    $scope.colorDropdownInformation = function (asset_id) {
      AuthService.getAutocompleteInformation('color', '', asset_id).then(function(data){
        $scope.colorsAvailable = data;
      });
    };

    $scope.autocompleteInformation = function (tagCategory, value, asset_id) {
      return AuthService.getAutocompleteInformation(tagCategory, value, asset_id);
    };

    function getNewContentFilter(key, value) {
      return $scope.filters.map(function (filter) {
        var field = Object.keys(filter);
        if (field[0] === key) {
          filter[key] = value;
          return filter;
        }

        return filter;
      });
    }

    function filterImages() {
      if ($scope.multimedia.some(function (m) { return m.type === 'image'; })) {
        AuthService.getFilteredImages(idToGet, $scope.filters).then(function (data) {
          $scope.multimedia = $scope.multimedia.map(function (m) {
            if (m.type === 'image') {
              return angular.extend({}, m, { assets: getImages(data.assets) });
            }

            return m;
          });
          $scope.busy = false;
        });
      }
    }
    $scope.busy = false;
    $scope.detailsInputTimeout = null;
    $scope.onDetailsChange = function (input) {
      if($scope.detailsInputTimeout != null){
        clearTimeout($scope.detailsInputTimeout);
      }

      // Make a new timeout set to go off in 800ms
      $scope.detailsInputTimeout = setTimeout(function () {
        $scope.busy = true;
        var filter = $scope.filters.filter(function (filter) { return filter.details !== undefined; });
        if (filter && filter.length > 0) {
          $scope.filters = getNewContentFilter('details', input.details);
        } else {
          $scope.filters.push({ details: input.details });
        }
        filterImages();
      }, 800);
    };

    $scope.colorInputTimeout = null;
    $scope.onColorChange = function (model) {
      $scope.busy = true;
      var filter = $scope.filters.filter(function (filter) { return filter.color !== undefined; });
      if (filter && filter.length > 0) {
        $scope.filters = getNewContentFilter('color', model.name);
      } else {
        $scope.filters.push({ color: model.name });
      }
      filterImages();
    };

    $scope.onColorInputChange = function (input) {
      if (!input.color && input.color === '') {
        $scope.onColorChange('');
      } else {
        $scope.onColorChange(input.color);
      }
    };

    $scope.onScreenfillChange = function (input) {
      $scope.busy = true;
      var filter = $scope.filters.filter(function (filter) { return filter.screenfill !== undefined; });
      if (filter && filter.length > 0) {
        $scope.filters = getNewContentFilter('screenfill', input.screenfill.id);
      } else {
        $scope.filters.push({ screenfill: input.screenfill.id });
      }
      filterImages();
    };

    $scope.onClearFilters = function () {
      $scope.filters = [];
      $scope.details = null;
      $scope.color = null;
      $scope.screenfill = null;
      document.getElementById('filtersForm').reset();

      filterImages();
    };
  });
