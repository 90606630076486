angular.module('dell-repository.unsupportedbrowser', ['ui.router', 'taiPlaceholder', 'ui.bootstrap', 'dell-repository.recoverPassword', 'dell-repository.modalWindow']).config(function config($stateProvider) {
    $stateProvider.state('unsupportedbrowser', {
        url : '/unsupportedbrowser',
        views : {
            "main" : {
                controller : 'UnsupportedBrowserCtrl',
                templateUrl : 'error/unsupportedBrowser.tpl.html'
            }
        },
        data : {
            pageTitle : 'Unsupported Browser'
        }
    });
}).controller('UnsupportedBrowserCtrl', function unsupportedBrowserCtrl($scope, $state) {});