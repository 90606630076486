angular.module( 'dell-repository.modalWindow', [
  'ui.router',
  'taiPlaceholder',
  'ui.bootstrap'
])

.config(function config( $stateProvider) {
  $stateProvider.state( 'modalWindow', {
    url: '/modalWindow',
    views: {
      "login": {
        controller: 'ModalWindowCtrl',
        templateUrl: 'modalWindow/modalWindow.tpl.html'
      }
    },
    data:{ pageTitle: 'Forgot Password' }
  });
})

.controller( 'ModalWindowCtrl', function ModalWindowCtrl($scope,AuthService,$state,$log,$uibModalInstance,info,$timeout) {
	$scope.data = {};
	$scope.data.title = info.title;
	$scope.data.message = info.message;
	if(info.timer){
		$timeout(autoDismiss, 3000);
	}
	function autoDismiss() {
		$uibModalInstance.dismiss('cancel');
	}	
	$scope.cancel = function() {
		$uibModalInstance.dismiss('cancel');
		
	};
});

