angular.module('dell-repository.filterTag', ['dell-repository.helpers'])
.directive('filterTag', ['RecursionHelper', function (RecursionHelper) {
  return {
    restrict : 'E',
    templateUrl: 'library/filterTag/filterTag.tpl.html',
    scope: {
      tags: '=',
      level: '=',
      elQuery: '@',
      set: '&',
      selected: '='
    },
    controller: function($scope) {

            // Handled by jQuery instead of angular, because there was a problem with $apply cycles and recursion (ng-change wasn't always firing)
            if (!!$scope.elQuery) {
              $($scope.elQuery).off('change', 'input');
              $($scope.elQuery).on('change', 'input', function (e) {
                if (e.target.checked) {
                  $(e.target).parent().find('ul.' + e.target.dataset.toggle).fadeIn();
                } else {
                  var ul = $(e.target).parent().find('ul');
                  ul.fadeOut();
                  ul.find('input.filterCheckBox:checked').prop('checked', false);
                }

                var checked = [];
                $($scope.elQuery + ' input:checked').each(function () {
                  checked.push(this.name);
                });

                $scope.set({elements: checked});
              });
            }

      $scope.doCheck = function (elementId, level, tag) {
        if (!!$scope.selected && $scope.selected.length > 0) {
          var isTagIn = $scope.selected.includes(tag.name.toLowerCase());
          if(isTagIn)
          {
            $('#' + elementId).parent().find('ul.' + level).fadeIn();
          }
          return isTagIn;
        }
        return false;
      };
    },
    compile: function(element) {
      return RecursionHelper.compile(element);
    }
  };
}]);