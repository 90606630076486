angular.module('dell-repository.deleteContent', ['ui.router', 'taiPlaceholder', 'ui.bootstrap']).config(function config($stateProvider) {
	$stateProvider.state('deleteContent', {
		url : '/deleteContent',
		views : {
			"login" : {
				controller : 'DeleteContentCtrl',
				templateUrl : 'deleteContent/deleteContent.tpl.html'
			}
		},
		data : {
			pageTitle : 'Forgot Password'
		}
	});
}).controller('DeleteContentCtrl', function DeleteContentCtrl($scope, $uibModalInstance, $timeout, AuthService, $log,contentToDelete) {
	var dataSend;

	$timeout(function() {
		$scope.focusInput = true;
	}, 700);
	$scope.ok2 = function() {
		$scope.focusInput = false;
		AuthService.deleteContent(contentToDelete).then(function(authenticated) {
			$log.info('success');
			$uibModalInstance.close('success');
		}, function(err) {
			$log.info(err);
		});
	};
	$scope.cancel = function() {
		$uibModalInstance.dismiss('cancel');
	};

});

