import confirmationTpl from './confirmation.tpl.html';

angular.module('dell-repository.confirmation', ['ui.router', 'taiPlaceholder', 'ui.bootstrap']).config(function config($stateProvider) {
	$stateProvider.state('confirmation', {
		url : '/confirmation',
		views : {
			"main" : {
				controller : 'ConfirmationCtrl',
				template : confirmationTpl
			}
		},
		data : {
			pageTitle : 'DELL Retail Library'
		}
	});
}).controller('ConfirmationCtrl', function($document,$scope, $state, AuthService) {

	$scope.redirect = function() {
		if (AuthService.role() == 'admin') {
			$state.go('admin', {}, {
				reload : true
			});
		} else {
			$state.go('library', {}, {
				reload : true
			});
		}
	};
});
