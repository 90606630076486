angular.module('dell-repository.recoverPassword', ['ui.router', 'taiPlaceholder', 'ui.bootstrap'])
.config(function config($stateProvider) {
	$stateProvider.state('recoverPassword', {
		url : '/recoverPassword',
		views : {
			"login" : {
				controller : 'RecoverPasswordCtrl',
				templateUrl : 'recoverPassword/recoverPassword.tpl.html'
			}
		},
		data : {
			pageTitle : 'Forgot Password'
		}
	});
}).controller('RecoverPasswordCtrl', function RecoverPasswordCtrl($scope, $uibModalInstance) {

	//Close modal
	$scope.cancel = function() {
		$uibModalInstance.dismiss('cancel');
	};
});

