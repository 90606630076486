angular.module('dell-repository.deleteFolder', ['ui.router', 'taiPlaceholder', 'ui.bootstrap']).config(function config($stateProvider) {
	$stateProvider.state('deleteFolder', {
		url : '/deleteFolder',
		views : {
			"login" : {
				controller : 'DeleteFolderCtrl',
				templateUrl : 'remove/remove.tpl.html'
			}
		},
		data : {
			pageTitle : 'Delete Folder'
		}
	});
}).controller('DeleteFolderCtrl', function DeleteFolderCtrl($scope, $uibModalInstance, $timeout, AuthService, $log, folderId) {
	var dataSend;

	$timeout(function() {
		$scope.focusInput = true;
	}, 700);
	$scope.ok2 = function() {
		$scope.focusInput = false;
		AuthService.deleteFolder(folderId).then(function() {
			$log.info('success');
			$uibModalInstance.close('success');
		}, function(err) {
			$log.info(err);
		});
	};
	$scope.cancel = function() {
		$uibModalInstance.dismiss('cancel');
	};

});

