angular.module('dell-repository.assetShareUrl', ['ui.router', 'taiPlaceholder', 'ui.bootstrap', 'dell-repository.assetShareUrl']).config(function config($stateProvider) {
	$stateProvider.state('assetShareUrl', {
		url : '/assetShareUrl',
		views : {
			"login" : {
				controller : 'assetShareUrlCtrl',
				templateUrl : 'assetShareUrl/assetShareUrl.tpl.html'
			}
		},
		data : {
			pageTitle : 'Share Asset'
		}
	});
}).controller('assetShareUrlCtrl', function assetShareUrlCtrl($scope, $uibModalInstance, $timeout, AuthService, $log, $window,downloadUrl) {

	$scope.cancel = function() {
		$uibModalInstance.dismiss('cancel');
	};

	/*$scope.showAssetName = function() {
		return nameAsset;
	};
	$scope.showShareUrl = function() {
		return nameAsset;
	};*/
	$scope.urlShareContent = function() {


		return downloadUrl;
	};
	$scope.copyLink = function() {

			var copyTextarea = document.querySelector('#containerUrl');
			copyTextarea.select();

			try {
				var successful = document.execCommand('copy');
				var msg = successful ? 'successful' : 'unsuccessful';

				$("#assetShareContentWrap #assetUrlShare #copyButtonUrl").addClass("copyShareContent");
			} catch (err) {
				console.error('Unable to copy.');
			}



	};

});

