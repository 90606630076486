import landingCarousel from './landingCarousel.tpl.html';

angular
  .module("dell-repository.landingCarousel", [])
  .directive("landingCarousel", function() {
    return {
      restrict: "E",
      template: landingCarousel,
      controller: function($scope, $interval) {
        var h2 = "Welcome to the Retail Asset Library.", // leaving the possibility for custom text for each slide
          text =
            "The home for retail photography, video, applications, guides and templates.",
          timeout;
				const LEFT_ARROW = 37, RIGHT_ARROW = 39, SPACE = 32;
        let isPaused = false;

        $scope.slides = [
          { h2: h2, text: text, altText: "PC Gamer Dell Alienware Area 51" },
          { h2: h2, text: text, altText: "Image showing people raising their hands" },
          { h2: h2, text: text, altText: "Image showing a Dell graphical interface" },
          { h2: h2, text: text, altText: "Image showing people viewing content from a computer" }
        ];

        $scope.goTo = function(index, e) {
          $scope.slides[$scope.active].active = false;
          $scope.active = !!$scope.slides[index] ? index : 0;
          $scope.slides[$scope.active].active = true;
          $interval.cancel(timeout);
          timeout = $interval(function() {
            $scope.goTo($scope.active + 1);
          }, 5000);
        };

				$scope.onKeyUp = function(keyCode) {
					if (keyCode === LEFT_ARROW) {
            $scope.goTo($scope.active - 1);
					}
          if (keyCode === RIGHT_ARROW) {
            $scope.goTo($scope.active + 1);
					}
          if (keyCode === SPACE) {
            if(isPaused === false) {
              $interval.cancel(timeout);
              isPaused = true;
            } else {
              timeout = $interval(function() {
                $scope.goTo($scope.active + 1);
              }, 1000);
              isPaused = false;
            }
					}
        }

        $scope.active = 0;
        $scope.goTo($scope.active);
      }
    };
  });

