angular
  .module("dell-repository", [
    //THIRD PARTY MODULES
    "ngCookies",
    //LOCAL MODULES
    "constants",
    "dell-repository.admin",
    "dell-repository.findContent",
    "dell-repository.login",
    "dell-repository.privacy",
    "dell-repository.unsupportedbrowser",
    "dell-repository.library",
    "dell-repository.users",
    "dell-repository.verification",
    "ui.router",
    "ui.carousel"
  ])
  .config(function myAppConfig($stateProvider, $urlRouterProvider, $provide, $qProvider) {
    $provide.constant("jQuery", window.$);
    $urlRouterProvider.otherwise("/library");
    $qProvider.errorOnUnhandledRejections(false);
  })
  .directive("focusMe", function($timeout) {
    return {
      link: function(scope, element, attrs) {
        scope.$watch(attrs.focusMe, function(value) {
          if (value === true) {
            //$timeout(function() {
            element[0].focus();
            scope[attrs.focusMe] = false;
            //});
          }
        });
      }
    };
  })
  .directive("focusOn", function() {
    return function(scope, elem, attr) {
      scope.$on("focusOn", function(e, name) {
        if (name === attr.focusOn) {
          elem[0].focus();
        }
      });
    };
  })
  .factory("focus", function($rootScope, $timeout) {
    return function(name) {
      $timeout(function() {
        $rootScope.$broadcast("focusOn", name);
      }, 500);
    };
  })
  // .directive("tooltip", function() {
  //   return {
  //     restrict: "A",
  //     link: function(scope, element, attrs) {
  //       $(element).hover(
  //         function() {
  //           $(element).tooltip("show");
  //         },
  //         function() {
  //           $(element).tooltip("hide");
  //         }
  //       );
  //     }
  //   };
  // })
  .run(function(
    $location,
    $window,
    $rootScope,
    $state,
    AuthService,
    BrowserInfo,
    AUTH_EVENTS
  ) {
    trackingId = "here add the tracking id";
    $window.ga("create", trackingId, "auto");

    $rootScope.$on("$stateChangeStart", function(
      event,
      next,
      nextParams,
      fromState
    ) {
      $window.ga("send", "pageview", $location.path());

      // check whether the user's browser is supported or not
      var supportedBrowser = true;
      if (next.name.indexOf("unsupportedbrowser") === -1) {
        var isSupportedBrowser = BrowserInfo.isSupported();
        if (!isSupportedBrowser) {
          supportedBrowser = false;
          event.preventDefault();
          $state.go("unsupportedbrowser");
        }
      }

      if (supportedBrowser) {
        if ("data" in next && "authorizedRoles" in next.data) {
          var authorizedRoles = next.data.authorizedRoles;
          if (!AuthService.isAuthorized(authorizedRoles)) {
            event.preventDefault();
            $rootScope.$broadcast(AUTH_EVENTS.notAuthorized);
          }
        }

        if (!AuthService.isAuthenticated()) {
          var authorizedPages = [
            "login",
            "privacy",
            "verification",
            "confirmation",
            "error",
            "expired",
            "unsupportedbrowser"
          ];
          if (authorizedPages.indexOf(next.name) == -1) {
            event.preventDefault();
            $state.go("login");
          }
        }

        if (AuthService.isAuthenticated()) {
          var pages = ["login"];
          if (pages.indexOf(next.name) === 0) {
            event.preventDefault();
            $state.go("library");
          }
        }
      }
    });
  })
  .controller("AppCtrl", function AppCtrl(
    $scope,
    $location,
    $state,
    $log,
    AuthService,
    AUTH_EVENTS
  ) {
    $scope.tlData = {
      staticValue: 42,
      randomValue: Math.floor(Math.random() * 1000)
    };

    $scope.analytic = new Keen({
      projectId: "564226d496773d64549944a8",
      writeKey:
        "1858057fb4b0987128f9abef73a5562841129b0e31c7a6adff68beee45fa2d9b6d8fa64d8a1732c46674259698715f641d42c6eacde7568deec8f75524ba01b81aeffc0a13dc6fc72356767aa1c021bbb705ec2a631bb4b0ae4519f930c088a708d1300edd6dec39baf3bbb423c0d14b",
      requestType: "xhr"
    });

    $scope.sendAnalyticEvent = function(action, newEvent) {
      $scope.analytic.addEvent(action, newEvent, function(err, res) {
        if (err) {
          $log.info("Keen failed to log event.");
        }
      });
    };

    $scope.$on("$stateChangeSuccess", function(
      event,
      toState,
      toParams,
      fromState,
      fromParams
    ) {
      if (angular.isDefined(toState.data.pageTitle)) {
        $scope.pageTitle = toState.data.pageTitle;
      }
    });

    $scope.$on("trackDataEvent", function(event, action, data) {
      var newEvent = {
        user: {
          userId: AuthService.userId(),
          username: AuthService.username(),
          userRole: AuthService.role()
        },
        keen: {
          timestamp: new Date().toISOString(),
          location: {
            coordinates: []
          }
        }
      };

      if (action === "addToCollection" || action === "download") {
        newEvent.fileType = data.fileType;
        newEvent.fileName = data.fileName;
      }

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function(position) {
          AuthService.getLocation(
            position.coords.latitude,
            position.coords.longitude
          ).then(function(data) {
            var location = data.location;
            newEvent.keen.location.coordinates = [
              parseFloat(location.lat),
              parseFloat(location.lng)
            ];
            newEvent.location = location;
            $scope.sendAnalyticEvent(action, newEvent);
          });
        });
      } else {
        $scope.sendAnalyticEvent(action, newEvent);
      }
    });

    $scope.setCurrentUsername = function(name) {
      $scope.username = name;
    };
    //Logout click
    $scope.logout1 = function() {
      AuthService.logout().then(
        function(authenticated) {
          $state.go(
            "login",
            {},
            {
              reload: true
            }
          );
        },
        function(err) {
          $log.info(err);
        }
      );
    };
    //Manage AUTHENTICATION and AUTHORIZATION
    $scope.$on(AUTH_EVENTS.notAuthorized, function(event) {
      $state.go("login");
      $log.info("Not authorized");
    });
    $scope.$on(AUTH_EVENTS.notAuthenticated, function(event) {
      AuthService.logout();
      $state.go("login");
      $log.info("Session lost. Login again!");
    });
  });

if (!Array.prototype.includes) {
  Array.prototype.includes = function(searchElement /*, fromIndex*/) {
    "use strict";
    var O = Object(this);
    var len = parseInt(O.length, 10) || 0;
    if (len === 0) {
      return false;
    }
    var n = parseInt(arguments[1], 10) || 0;
    var k;
    if (n >= 0) {
      k = n;
    } else {
      k = len + n;
      if (k < 0) {
        k = 0;
      }
    }
    var currentElement;
    while (k < len) {
      currentElement = O[k];
      if (
        searchElement === currentElement ||
        (searchElement !== searchElement && currentElement !== currentElement)
      ) {
        return true;
      }
      k++;
    }
    return false;
  };
}

// https://tc39.github.io/ecma262/#sec-array.prototype.find
if (!Array.prototype.find) {
  Object.defineProperty(Array.prototype, "find", {
    value: function(predicate) {
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== "function") {
        throw new TypeError("predicate must be a function");
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      var thisArg = arguments[1];

      // 5. Let k be 0.
      var k = 0;

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return kValue.
        var kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return kValue;
        }
        // e. Increase k by 1.
        k++;
      }

      // 7. Return undefined.
      return undefined;
    }
  });
}
