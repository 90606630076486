/*** IMPORTS FROM imports-loader ***/
var angular = require("angular");

var angular=window.angular,ngModule;
try {ngModule=angular.module(["ng"])}
catch(e){ngModule=angular.module("ng",[])}
var v1="<div id=\"addContentWrap\" class=\"modal-body\" ng-class=\"{biggerModal:next}\">\n<span ng-click=\"cancel()\"><img src=\"/assets/images/admin/close_window.png\"/></span>\n<form class=\"formAddContent\" ng-submit=\"sendEmail(data)\">\n<div class=\"titleContainer\">\n<h3 id=\"addContentTitle\" class=\"dellTitle\">Request for content</h3>\n</div>\n<div id=\"secondForm\" ng-show=\"screens.selected === screens.list.second\">\n<ul>\n<li>\n<input limit-to=\"256\" class=\"subjectInput\" placeholder=\"Subject\" id=\"subject\" ng-class=\"{'error': error.subject}\" ng-model=\"data.subject\"/>\n</li>\n<p class=\"errorMessage\" ng-show=\"error.subject\">\nPlease enter a valid subject.\n</p>\n<li class=\"description\">\n<textarea ng-model=\"data.body\" class=\"form-control dellEmailTextArea\" placeholder=\"Please provide a description of the content you are looking for.\" ng-class=\"{'error': error.body}\" maxlength=\"4000\"></textarea>\n<p class=\"errorMessage\" ng-show=\"error.body\">\nPlease enter a valid body.\n</p>\n</li>\n</ul>\n<div class=\"right\">\n<button id=\"addContentModalButton\" ng-disabled=\"control.uploading\" type=\"submit\" class=\"btn btn-primary button1 addContentModalButton\" tabindex=\"10\">\nSend\n</button>\n</div>\n</div>\n</form>\n</div>\n";
var id1="library/email/email.tpl.html";
var inj=angular.element(window.document).injector();
if(inj){inj.get("$templateCache").put(id1,v1);}
else{ngModule.run(["$templateCache",function(c){c.put(id1,v1)}]);}
module.exports=v1;
