import privacyTemplate from './privacy.tpl.html';

angular.module('dell-repository.privacy', ['ui.router','taiPlaceholder', 'ui.bootstrap']).config(function config($stateProvider) {
	$stateProvider.state('privacy', {
		url : '/privacy',
		views : {
			"main" : {
				controller : 'PrivacyCtrl',
				template : privacyTemplate
			}
		},
		data : {
			pageTitle : 'Privacy'
		}
	});
}).controller('PrivacyCtrl', function PrivacyCtrl($scope) {
	$scope.data = {};
	$scope.date = new Date();
});
