/*** IMPORTS FROM imports-loader ***/
var angular = require("angular");

var angular=window.angular,ngModule;
try {ngModule=angular.module(["ng"])}
catch(e){ngModule=angular.module("ng",[])}
var v1="<div id=\"assetShareWrap\" class=\"modal-body-share\">\n<div id=\"assetShareContentWrap\">\n<span ng-click=\"cancel()\"><img src=\"/assets/images/admin/close_window.png\"/></span>\n<div class=\"titleContainer\">\n<h3 id=\"assetShareTitle\">Share</h3>\n</div>\n<div class=\"col-md-12\" id=\"textShareCopyUrl\">Copy this link to share with others</div>\n<div id=\"assetUrlShare\" class=\"col-lg-12\">\n<textarea readonly=\"readonly\" id=\"containerUrl\" class=\"col-lg-11\">{{urlShareContent()}}</textarea>\n<button id=\"copyButtonUrl\" class=\"col-lg-1\" ng-click=\"copyLink()\">Copy</button>\n</div>\n</div>\n</div>\n";
var id1="library/assetShareUrl/assetShareUrl.tpl.html";
var inj=angular.element(window.document).injector();
if(inj){inj.get("$templateCache").put(id1,v1);}
else{ngModule.run(["$templateCache",function(c){c.put(id1,v1)}]);}
module.exports=v1;
