angular.module('dell-repository.deleteCollection', ['ui.router', 'taiPlaceholder', 'ui.bootstrap']).config(function config($stateProvider) {
	$stateProvider.state('deleteCollection', {
		url : '/deleteCollection',
		views : {
			"login" : {
				controller : 'DeleteCollectionCtrl',
				templateUrl : 'deleteCollection/deleteCollection.tpl.html'
			}
		},
		data : {
			pageTitle : 'Forgot Password'
		}
	});
}).controller('DeleteCollectionCtrl', function DeleteCollectionCtrl($scope, $uibModalInstance, $timeout, AuthService, $log, collectionToDelete, folderId) {
	var dataSend;
	var blocked = false;
	$scope.allCollection = false;
	if (collectionToDelete === '*') {
		$scope.allCollection = true;
	}

	$timeout(function() {
		$scope.focusInput = true;
	}, 700);
	$scope.ok2 = function() {
		$scope.focusInput = false;
		if (!blocked) {
			blocked = true;
			$timeout(function() {
				angular.forEach(collectionToDelete, function (value, key) {
					AuthService.deleteCollection(folderId, value.id).then(function(authenticated) {
						blocked = false;
					}, function(err) {
						$log.info(err);
					});
				});
				$log.info('success');
				$uibModalInstance.close('success');
			});
		}
	};
	$scope.cancel = function() {
		$uibModalInstance.dismiss('cancel');
	};

});

