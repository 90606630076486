import addUserTpl from './addUser.tpl.html';

angular.module('dell-repository.addUser', ['ui.router', 'taiPlaceholder', 'ui.bootstrap']).config(function config($stateProvider) {
	$stateProvider.state('addUser', {
		url : '/addUser',
		views : {
			"login" : {
				controller : 'AddUserCtrl',
				template : addUserTpl
			}
		},
		data : {
			pageTitle : 'Forgot Password'
		}
	});
}).controller('AddUserCtrl', function AddUserCtrl($scope, $uibModalInstance, $timeout, AuthService, $log) {
	var dataSend;

	$timeout(function() {
		$scope.focusInput = true;
	}, 700);

	//Close modal
	$scope.success = false;
	$scope.cancel = function() {
		$uibModalInstance.dismiss('cancel');
	};
	$scope.validInfo = false;
	$scope.validInfo2 = false;
	$scope.roleError = [false, false, false, false, false];
	$scope.inputError = [false, false, false, false, false];
	$scope.inputSuccess = [false, false, false, false, false];
	$scope.ok = function(data) {
		$scope.roleError = [false, false, false, false, false];
		$scope.inputError = [false, false, false, false, false];
		$scope.inputSuccess = [false, false, false, false, false];
		var usersToAdd = {
			"users" : []
		};
		if (data.email1) {
			if (data.role1) {
				AuthService.createUserOneByOne(data.email1,data.role1).then(function(authenticated) {
					$scope.inputSuccess[0] = true;
					$scope.inputError[0] = false;
					data.role1 = "";
					data.email1 = "";
				}, function(err) {
					$scope.inputError[0] = true;
					$scope.inputSuccess[0] = false;
				});
			} else {
				$scope.roleError[0] = true;
			}
		}
		if (data.email2) {
			if (data.role2) {
				AuthService.createUserOneByOne(data.email2,data.role2).then(function(authenticated) {
					$scope.inputSuccess[1] = true;
					$scope.inputError[1] = false;
					data.role2 = "";
					data.email2 = "";
				}, function(err) {
					$scope.inputError[1] = true;
					$scope.inputSuccess[1] = false;
				});
			} else {
				$scope.roleError[1] = true;
			}
		}
		if (data.email3) {
			if (data.role3) {
				AuthService.createUserOneByOne(data.email3,data.role3).then(function(authenticated) {
					$scope.inputSuccess[2] = true;
					$scope.inputError[2] = false;
					data.role3 = "";
					data.email3 = "";
				}, function(err) {
					$scope.inputError[2] = true;
					$scope.inputSuccess[2] = false;
				});
			} else {
				$scope.roleError[2] = true;
			}
		}
		if (data.email4) {
			if (data.role4) {
				AuthService.createUserOneByOne(data.email4,data.role4).then(function(authenticated) {
					$scope.inputSuccess[3] = true;
					$scope.inputError[3] = false;
					data.role4 = "";
					data.email4 = "";
				}, function(err) {
					$scope.inputError[3] = true;
					$scope.inputSuccess[3] = false;
				});
			} else {
				$scope.roleError[3] = true;
			}
		}
		if (data.email5) {
			if (data.role5) {
				AuthService.createUserOneByOne(data.email5,data.role5).then(function(authenticated) {
					$scope.inputSuccess[4] = true;
					$scope.inputError[4] = false;
					data.role5 = "";
					data.email5 = "";
				}, function(err) {
					$scope.inputError[4] = true;
					$scope.inputSuccess[4] = false;
				});
			} else {
				$scope.roleError[4] = true;
			}
		}
		$timeout(showSuccess, 2000);

	};
	function showSuccess(){
		var sw=true;
		for(i=0;i<5;i++){
			if($scope.roleError[i]||$scope.inputError[i]){
				sw = false;
			}	
		}
		$scope.success = sw;		
	}
	$scope.ok2 = function() {
		$scope.focusInput = false;
		$uibModalInstance.close('success');
	};
});

